import React from 'react';
import styled from 'styled-components';
import { device, fonts } from '../../../utils/theme.util';
import elliot from '../../../assets/images/aboutus/elliot.png';
import sean from '../../../assets/images/aboutus/sean.png';
import timo from '../../../assets/images/aboutus/timofey.png';
import javier from '../../../assets/images/aboutus/javi.png';
import tiffany from '../../../assets/images/aboutus/tiffany.png';
import max from '../../../assets/images/aboutus/max.png';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 275px;

    @media ${device.tabletS} {
        margin-top: 150px;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 68px;
`;

const TitleText = styled.div`
    font-weight: 600;
    font-size: 80px;
    line-height: 84px;
    text-align: center;
    letter-spacing: -2.50105px;
    margin-bottom: 25px;
    font-family: ${fonts.primary};

    @media ${device.tabletS} {
        font-size: 60px;
        line-height: 60px;
    }
`;

const SubText = styled.div`
    font-size: 21px;
    line-height: 39px;
    text-align: center;
    letter-spacing: -0.656526px;
    color: #696871;
    font-family: ${fonts.primary};
`;

const PeopleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 57px;

    @media ${device.tabletS} {
        flex-direction: column;
    }
`;

const PersonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${device.tabletS} {
        margin-bottom: 36px;
    }

    transform: translate(0%);
    transition: 0.3s ease-out;

    &:hover {
        transform: translate(0%, -5%);
        transition: 0.3s ease-out;
        cursor: pointer;
    }
`;

const Image = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 25px;
`;

const NameText = styled.div`
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.750316px;
    color: #19191b;
    margin-bottom: 5px;
    font-family: ${fonts.primary};
`;

const RoleText = styled.div`
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.500211px;
    color: #696871;
    font-family: ${fonts.primary};
`;

export function MeetTheTeam() {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    const navTo = (username: string) => {
        window.location.href = `https://sosh.me/${username}`;
    };

    return (
        <Slide right>
            <Container>
                <TextContainer>
                    <TitleText>Meet the Team</TitleText>
                    <SubText>
                        Meet our exceptionally talented team of Creators, ready to harness
                        the Force for good.
                    </SubText>
                </TextContainer>
                <PersonWrapper onClick={() => navTo('farkas')}>
                    <Image src={sean} />
                    <NameText>Sean Farkas</NameText>
                    <RoleText>CMO</RoleText>
                </PersonWrapper>
                <PeopleWrapper>
                    <PersonWrapper onClick={() => navTo('the_social_hacker')}>
                        <Image src={elliot} />
                        <NameText>Elliot Briant</NameText>
                        <RoleText>Co-Founder</RoleText>
                    </PersonWrapper>
                    <PersonWrapper onClick={() => navTo('timo')}>
                        <Image src={timo} />
                        <NameText>Timofey Mahklay</NameText>
                        <RoleText>Co-Founder</RoleText>
                    </PersonWrapper>
                </PeopleWrapper>
                <PeopleWrapper>
                    <PersonWrapper onClick={() => navTo('maxrivera')}>
                        <Image src={max} />
                        <NameText>Max Rivera</NameText>
                        <RoleText>Marketing</RoleText>
                    </PersonWrapper>
                    <PersonWrapper onClick={() => navTo('tiffribx')}>
                        <Image src={tiffany} />
                        <NameText>Tiffany Ribeiro</NameText>
                        <RoleText>Community Manager</RoleText>
                    </PersonWrapper>
                </PeopleWrapper>
                <PersonWrapper onClick={() => navTo('javier')}>
                    <Image src={javier} />
                    <NameText>Javier Mendoza</NameText>
                    <RoleText>Product Designer</RoleText>
                </PersonWrapper>
            </Container>
        </Slide>
    );
}
