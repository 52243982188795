import React from 'react';

import { Footer } from '../nav/Footer';
import { NavBar } from '../nav/NavBar';
import { AppAnalysis } from './top/AppAnalysis';
import { CallToAction } from './top/CallToAction';
import { Integrations } from './top/Integrations';
import { MeetCreators } from './top/MeetCreators';
import { Newsletter } from './top/Newsletter';
import { OurFeatures } from './top/OurFeatures';
import { WhyPaySplit } from './top/WhyPaySplit';

export function LandingPage() {
    return (
        <>
            <NavBar />

            <CallToAction />

            <div
                style={{
                    width: '100%',
                    display: 'wrap',
                    maxWidth: 1800,
                    position: 'relative',
                    flexDirection: 'column',
                }}
            >
                <OurFeatures />

                <MeetCreators />

                <WhyPaySplit />

                <AppAnalysis />

                <Integrations />

                <Newsletter />
            </div>

            <Footer />
        </>
    );
}
