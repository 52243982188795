import React, { useState } from 'react';
import styled from 'styled-components';
import { device, fonts } from '../../utils/theme.util';
import { Footer } from '../nav/Footer';
import { NavBar } from '../nav/NavBar';
import { Category } from './components/Category';
import background from '../../assets/images/background1.png';
import itguy from '../../assets/images/faq/itguy.png';
import gettingStarted from '../../assets/images/faq/getting_started.png';
import profile from '../../assets/images/faq/profile.png';
import wallet from '../../assets/images/faq/wallet.png';
import services from '../../assets/images/faq/services.png';
import { FAQs } from './components/FAQs';
import { Question } from './components/Question';

const Container = styled.div`
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(250, 251, 251, 0.0001) 0%, #f8fbff 100%);
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-top: 70px;
    z-index: 1;
    height: calc(100vh - 200px);

    @media ${device.laptopS} {
        height: 25vh;
        margin-top: 125px;
    }
`;

const Background = styled.img`
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    max-height: 1000px;
    min-height: 800px;

    @media ${device.laptopS} {
        display: none;
    }
`;

const ItGuy = styled.img`
    height: 405px;
    width: 435px;
    z-index: 1;

    @media ${device.laptopS} {
        display: none;
    }
`;

const TitleText = styled.h1`
    font-size: 64px;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.white};
    text-align: center;
    z-index: 1;

    @media ${device.laptopS} {
        color: ${({ theme }) => theme.black};
    }
`;

const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 150px;

    @media ${device.laptopS} {
        margin-top: 2rem;
    }
`;

const CategoryText = styled.p`
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #5a7184;

    margin-top: 1rem;
    margin-bottom: 2rem;

    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 36px;
    font-weight: 600;

    @media ${device.tabletS} {
        font-size: 30px;
    }
`;

const Categories = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media ${device.laptopS} {
        flex-direction: column;
    }
`;

const FAQsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    @media ${device.laptopS} {
        width: 95%;
    }
`;

const FAQTitleText = styled.h1`
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 14px;
`;

const FAQSubText = styled.p`
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #5a7184;
    margin-bottom: 52px;
`;

const FAQQuestionContainer = styled.div`
    margin-bottom: 150px;
`;

const StillQuestionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const StillQuestionsTitle = styled.h1`
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 16px;

    @media ${device.laptopS} {
        margin-left: 2rem;
        margin-right: 2rem;
    }
`;

const StillQuestionsSubText = styled.p`
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #5a7184;
    margin-bottom: 64px;
    max-width: 610px;
`;

const StillQuestionsBox = styled.a`
    background-color: ${({ theme }) => theme.white};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 24px 40px 24px;
    border: 1px solid lightgrey;
    text-decoration: none;

    @media ${device.laptopS} {
        margin-bottom: 64px;
    }
`;

const StillQuestionsImage = styled.img`
    width: 100px;
    height: 100px;
`;

const EmailText = styled.p`
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin: 16px 0px 8px 0px;
`;

const EmailSubText = styled.p`
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #5a7184;
`;

const ID_MAP: Record<number, string> = {
    0: 'general',
    1: 'profile',
    2: 'wallet',
};

export function FAQ() {
    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    const [selectedId, setSelectedId] = useState(0);
    const [expandedIndicies, setExpandedIndicies] = useState<number[]>([]);

    const category = ID_MAP[selectedId];

    const handleClick = (index: number) => {
        const i = expandedIndicies.findIndex((i) => i === index);
        if (i !== -1) {
            const newArray = [...expandedIndicies];
            newArray.splice(i, 1);
            setExpandedIndicies(newArray);
        } else {
            setExpandedIndicies([...expandedIndicies, index]);
        }
    };

    return (
        <Container>
            <NavBar />
            <Background src={background} />
            <HeaderContainer>
                <TitleText>We are here to help you</TitleText>
                <ItGuy src={itguy} />
            </HeaderContainer>
            <Fade>
                <CategoryContainer>
                    <br />
                    <br />
                    <CategoryText>
                        Select a category and scroll down <br /> to quickly find the help
                        you need
                    </CategoryText>
                    <Categories>
                        <Category
                            title='For Getting Started'
                            subtitle='All you need from A-Z for getting started.'
                            onClick={() => setSelectedId(0)}
                            selected={selectedId === 0}
                            image={gettingStarted}
                        />
                        <Category
                            title='Söshme Profile'
                            subtitle='The most frequently asked questions about the Söshme Profile.'
                            onClick={() => setSelectedId(1)}
                            selected={selectedId === 1}
                            image={profile}
                        />
                        <Category
                            title='Söshme Wallet'
                            subtitle='All questions regarding the Söshme Wallet.'
                            onClick={() => setSelectedId(2)}
                            selected={selectedId === 2}
                            image={wallet}
                        />
                    </Categories>
                </CategoryContainer>
            </Fade>
            <Slide>
                <FAQsContainer>
                    <FAQTitleText>FAQ</FAQTitleText>
                    <FAQSubText>Frequently asked questions about Söshme</FAQSubText>
                    <FAQQuestionContainer>
                        {(FAQs as any)[category].map((faq: any, i: number) => {
                            return (
                                <Question
                                    questionText={faq.question}
                                    answerText={faq.answer}
                                    expanded={expandedIndicies.includes(i)}
                                    setExpanded={() => handleClick(i)}
                                />
                            );
                        })}
                    </FAQQuestionContainer>
                </FAQsContainer>
            </Slide>
            <Fade>
                <StillQuestionsContainer>
                    <StillQuestionsTitle>Still have questions?</StillQuestionsTitle>
                    <StillQuestionsSubText>
                        If you cannot find an answer to your question in our FAQ, you can
                        always contact us. We will reply back shortly!
                    </StillQuestionsSubText>
                    <StillQuestionsBox href='mailto:services@paysplit.io'>
                        <StillQuestionsImage src={services} />
                        <EmailText>services@paysplit.io</EmailText>
                        <EmailSubText>Alternative way to get answer faster.</EmailSubText>
                    </StillQuestionsBox>
                </StillQuestionsContainer>
            </Fade>
            <Footer />
        </Container>
    );
}
