import styled from 'styled-components';
import { fonts, device } from '../utils/theme.util';

export const BORDER_RADIUS = `${device.tabletS ? '8px' : '8px'}`;
export const SMALL_PADDING = `${device.tabletS ? '10px' : '10px'}`;
export const MEDIUM_PADDING = `${device.tabletS ? '12px' : '12px'}`;
export const LARGE_PADDING = `${device.tabletS ? '15px' : '15px'}`;
export const HORIZONTAL_MARGIN = `${device.tabletS ? '18px' : '18px'}`;

interface NavButtonProps {
    noPointer?: boolean;
}

export const NavButton = styled.a<NavButtonProps>`
    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    margin-left: ${device.tabletS ? '10px' : '30px'};
    font-size: 16px;
    font-weight: 500;
    margin: 0px 28px;
    text-decoration: none;

    &:hover {
        color: #e86e8a;
    }

    ${(props) =>
        !props.noPointer &&
        `&:hover {
        cursor: pointer;
    }`}
`;

export const TopText = styled.h1`
    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 36px;
    font-weight: 600;

    align-self: center;
    text-align: center;

    margin-top: ${MEDIUM_PADDING};

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 80%;
    margin-left: 10%;
    margin-right: 10%;

    @media ${device.tabletS} {
        font-size: 30px;
        margin-top: 0;
    }
`;

export const SubText = styled.p`
    font-famiy: ${fonts.primary};
    color: #5a7184;
    background-color: transparent;
    font-size: 20px;
    font-weight: 200;
    flex: 1;
    margin-left: ${HORIZONTAL_MARGIN};
    margin-right: ${HORIZONTAL_MARGIN};
    text-align: center;
    align-self: center;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 3%;
    @media ${device.tabletS} {
    }
`;

export const NormalText = styled.p`
    font-famiy: ${fonts.primary};
    color: #5a7184;
    background-color: transparent;
    font-size: 18px;
    font-weight: 200;
    flex: 1;
    text-align: left;
    align-self: center;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 100%;

    @media ${device.tabletS} {
    }
`;
