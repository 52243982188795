import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils/theme.util';
import { DataType, InfoRow } from './InfoRow';

interface Props {
    DATA: DataType;
    creatorImage: string;
    reversed?: boolean;
}

const Container = styled.div<{ reversed: boolean }>`
    display: flex;
    flex-direction: ${({ reversed }) => (reversed ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: space-around;
    padding-top: 300px;
    margin: 0px 2rem;

    @media ${device.laptopS} {
        padding-top: 150px;
        flex-direction: column;
    }
`;

const CreatorImage = styled.img`
    width: 350px;
    height: 707px;

    @media ${device.laptopS} {
        width: 60%;
        height: auto;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 480px;

    @media ${device.laptopS} {
        margin-bottom: 3rem;
    }
`;

export function ProductFeatures({ DATA, creatorImage, reversed = false }: Props) {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    return (
        <Slide up>
            <Container reversed={reversed}>
                <TextContainer>
                    {DATA.map((_, i) => (
                        <InfoRow index={i} DATA={DATA} />
                    ))}
                </TextContainer>
                <CreatorImage src={creatorImage} />
            </Container>
        </Slide>
    );
}
