import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.a<Partial<Props>>`
    padding: 10px 12px;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    ${({ outlined }) =>
        outlined
            ? 'border: 2px solid #1565D8; color: #1565D8;'
            : 'background-color: #1565D8; color: white;'}

    text-decoration: none;

    &:hover {
        transform: scale(1.1);
    }

    transition: all 0.2s ease-in-out;
`;

const Arrow = css<Partial<Props>>`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: ${({ outlined }) => (outlined ? '#1565d8' : 'white')};
    box-sizing: border-box;
    &:after,
    &:before {
        content: '';
        box-sizing: border-box;
    }
    width: 10px;
    height: 10px;
    border-width: 3px 3px 0 0;
    border-style: solid;
    margin-left: 14px;
    margin-right: 6px;
    &:before {
        right: 0;
        top: -2px;
        position: absolute;
        height: 3px;
        box-shadow: inset 0 0 0 32px;
        transform: rotate(-45deg);
        width: 12px;
        transform-origin: right top;
    }
`;

const RightArrow = styled.i`
    ${Arrow}
    transform: rotate(45deg);
`;

const Text = styled.span`
    font-weight: bold;
    font-size: 16px;
`;

interface Props {
    outlined: boolean;
    children: React.ReactChild;
    onPress?: () => void;
}

export function PillButton({ outlined, children, onPress }: Props) {
    return (
        <Container outlined={outlined} href={onPress ? undefined : '/'} onClick={onPress}>
            <>
                <Text>{children}</Text>
                <RightArrow outlined={outlined} />
            </>
        </Container>
    );
}
