import React from 'react';
import styled from 'styled-components';

interface Props {
    onClose: () => void;
    visible: boolean;
}

const Container = styled.div<{ visible: boolean }>`
    z-index: 9999;
    position: fixed;
    width: 100%;
    background-color: #295e85;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 0vh;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s, height 0.5s;
    overflow-y: auto;

    ${(props) =>
        props.visible &&
        `
        height: 100vh;
        opacity: 1;
        visibility: visible;
    `}
`;

const Close = styled.span`
    position: absolute;
    top: 0rem;
    right: 1.25rem;
    font-size: 60px;
    color: #fff;
`;

const NavItem = styled.a`
    color: white;
    padding: 1.25rem;
    border-radius: 0.5rem;
    font-size: 18px;
    text-decoration: none;
`;

export const MobileNav: React.FC<Props> = ({ onClose, visible }) => {
    return (
        <Container visible={visible}>
            <Close onClick={onClose}>&times;</Close>
            <NavItem href='/'>Home</NavItem>
            <NavItem href='/products/profile'>Profile Features</NavItem>
            <NavItem href='/products/wallet'>Wallet Features</NavItem>
            {/* <NavItem href='/products/business'>PaySplit for Business</NavItem> */}
            <NavItem href='/about-us'>About Us</NavItem>
            {/* <NavItem
                rel='noopener noreferrer'
                target='_blank'
                href='https://climate.stripe.com/4ZBlzM'
            >
                Climate
            </NavItem> */}
            <NavItem href='/faq'>Help</NavItem>
            <NavItem href='/contact-us'>Contact Us</NavItem>
            <NavItem href='/signup' style={{ fontWeight: 650 }}>
                Get Söshed
            </NavItem>
        </Container>
    );
};
