import React from 'react';
import styled from 'styled-components';
import socials1 from '../../../../assets/images/products/profile/Socials-left.png';
import socials2 from '../../../../assets/images/products/profile/socials-right.png';
import { device } from '../../../../utils/theme.util';

const Container = styled.div`
    width: 100%;
    margin-top: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media ${device.laptopS} {
        margin-top: 150px;
        flex-direction: column-reverse;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

const LeftImage = styled.img`
    width: 400px;
    max-width: 80vw;
    height: auto;
    margin-right: 2rem;
`;

const RightImage = styled.img`
    width: 376px;
    max-width: 80vw;
    height: auto;
    margin-left: 2rem;
`;

const Middle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    @media ${device.laptopS} {
        margin: 2rem 1.25rem;
    }
`;

const TitleText = styled.p`
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 1rem;
`;

const SubText = styled.p`
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #5a7184;
`;

const Button = styled.a`
    &:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    padding: 12px 40px;
    border-radius: 0.5rem;
    background-color: #D35678;
    color: ${({ theme }) => theme.white};
    font-size: 16px;
    box-shadow: 1px 1px 15px -10px #000000;
    margin-top: 2rem;
    line-height: 25px;
    text-decoration: none;

    transition: all 0.2s ease-in-out;
`;

export function GrowYourBrand() {
    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');

    return (
        <Fade>
            <Container>
                <ImageContainer>
                    <LeftImage src={socials1} />
                </ImageContainer>
                <Middle>
                    <TitleText>
                        Grow your brand presence and following with Söshme.
                    </TitleText>
                    <SubText>Try Söshme free. No credit card required.</SubText>
                    <Button href='https://apps.apple.com/us/app/paysplit/id1547157801'>
                        Sign Up for Free
                    </Button>
                </Middle>
                <ImageContainer>
                    <RightImage src={socials2} />
                </ImageContainer>
            </Container>
        </Fade>
    );
}
