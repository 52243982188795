import React from 'react';
import styled from 'styled-components';
import icon from '../../../../assets/images/products/wallet/Icon.svg';
import illustration from '../../../../assets/images/products/wallet/Illustration.png';
import { device } from '../../../../utils/theme.util';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 8rem auto 0px;

    @media ${device.tabletS} {
        flex-direction: column;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 550px;
`;

const GreenText = styled.p`
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #36b37e;
`;

const TitleText = styled.p`
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 0.75rem;
`;

const SubText = styled.p`
    font-size: 18px;
    line-height: 32px;
    color: #5a7184;
`;

const ChecksContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 2rem;

    @media ${device.tabletS} {
        flex-direction: column;
    }
`;

const ChecksColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
`;

const CheckRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.5rem;
`;

const Check = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 1rem;
`;

const CheckText = styled.p`
    font-size: 16px;
    line-height: 22px;
    color: #183b56;
    max-width: 204px;
    opacity: 0.87;
`;

const Illustration = styled.img`
    width: 390px;
    height: 450px;

    @media ${device.laptopS} {
        display: none;
    }
`;

const CheckRow = ({ text }: { text: string }) => {
    return (
        <CheckRowContainer>
            <Check src={icon} />
            <CheckText>{text}</CheckText>
        </CheckRowContainer>
    );
};

export function WeNever() {
    return (
        <Container>
            <LeftContainer>
                <GreenText>We Never</GreenText>
                <TitleText>Sell your information</TitleText>
                <SubText>
                    Our main concern is keeping your data free from any and all harm.
                    We&apos;re not a Multi-Channel Network, and we&apos;re not in it for
                    the short run. We won&apos;t ever sell or offer your information.
                    We&apos;re here to empower creators.
                </SubText>
                <ChecksContainer>
                    <ChecksColumn>
                        <CheckRow text='Organize your data' />
                        <CheckRow text='Personal data encrypted' />
                        <CheckRow text="Own your data & know your audience" />
                    </ChecksColumn>
                    <ChecksColumn>
                        <CheckRow text='Unleash new insights' />
                        <CheckRow text='Growth-driven, results-oriented' />
                    </ChecksColumn>
                </ChecksContainer>
            </LeftContainer>
            <Illustration src={illustration} />
        </Container>
    );
}
