import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils/theme.util';
import { DATA, Item } from '../products/wallet/components/Stats';

const Divider = styled.div`
    background: rgba(196, 196, 196, 0.3);
    width: 80%;
    height: 6px;
`;

const StatsTitle = styled.div`
    margin-top: 3.125rem;
    font-size: 1.875rem;
    line-height: 4rem;
    letter-spacing: 0.2px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 1rem;
    width: 80%;

    @media ${device.mobileL} {
        margin-bottom: 0rem !important;
    }
`;

const StatsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 6rem;

    @media ${device.tabletS} {
        flex-direction: column;
        padding-top: 20px;
    }
`;

export const Stats = () => {
    return (
        <>
            <Divider />
            <StatsTitle>
                Everything you need to know about your creator business in one place.
            </StatsTitle>
            <StatsContainer>
                {DATA.map((_, i) => (
                    <Item key={i} index={i} />
                ))}
            </StatsContainer>
        </>
    );
};
