import React from 'react';
import { NavBar } from '../../nav/NavBar';

export function BusinessProduct() {
    return (
        <>
            <NavBar />
        </>
    );
}
