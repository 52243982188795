/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import { device, fonts } from '../../../utils/theme.util';

import newsletter from '../../../assets/images/illustration-newsletter.png';

const NewsletterContainer = styled.div`
    width: 90%;
    margin-left: 5%;
    display: flex;
    align-self: center;

    margin-top: 150px;
    z-index: 1;

    position: relative;
    justify-content: flex-end;

    border-radius: 12px;

    background-color: #295e85;

    // margin-top: ;
    @media ${device.tabletS} {
        margin-top: 94%;
    }
`;

const IllustrationImage = styled.img`
    width: 40%;
    z-index: 4;
    display: block;
    top: 12%;
    left: 10%;
    position: absolute;
    align-self: flex-start;

    @media ${device.tabletS} {
        width: 80%;
        margin-left: 0%;
        margin-right: 10%;
        margin-top: 20%;
        margin-bottom: 10%;
        z-index: 2;
        display: block;
        align-self: center;
        position: relative;
        // background-color: red;
    }
`;

const TextContainer = styled.div`
    flex-direction: column;
    diplay: flex;
    margin-right: 10%;
    margin-top: 0%;
    z-index: 3;
    width: 38%;
    align-self: center;

    // background-color: red;

    // position: relative;

    @media ${device.tabletS} {
        margin-top: 0px;

        align-self: center;

        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
`;

const TopText = styled.h1`
    font-famiy: ${fonts.primary};
    color: #fff;
    background-color: transparent;
    font-size: 36px;
    font-weight: 600;

    text-align: left;

    margin-top: 18px;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 100%;

    @media ${device.tabletS} {
        font-size: 30px;
        text-align: center;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
`;

const NormalText = styled.p`
    font-famiy: ${fonts.primary};
    color: #fff;
    background-color: transparent;
    font-size: 18px;
    font-weight: 200;
    flex: 1;
    text-align: left;
    align-self: center;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 100%;

    margin-top: 20px;

    @media ${device.tabletS} {
        text-align: center;
        width: 80%
        margin-left: 10%;
        margin-right: 10%;
    }
`;

const EmailContainer = styled.div`
    height: 65px;
    // width: 200%;
    background-color: #fff;
    display: flex;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 40px;

    width: 110%;
    margin-left: -5%;

    border-radius: 12px;
`;

const EmailInputField = styled.input`
    background-color: transparent;
    font-family: ${fonts};
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.gray600};
    padding-left: 5px;

    border: none;

    text-align: left;
    width: 65%;

    &:focus {
        outline: none;
    }
`;

const ButtonText = styled.p`
    font-size: 16px;
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    font-famiy: ${fonts.primary};
    color: ${({ theme }) => theme.white};
    font-weight: 600;
    flex: 1;
`;

const Button = styled.button`
    font-famiy: ${fonts.primary};
    background-color: #d35678;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: 35%;
    height: 80%;
    margin-right: 10px;

    &:focus {
        outline: none;
    }

    transform: translate(0%);
    transition: 0.3s ease-out;

    &:hover {
        transform: translate(0%, -5%);
        transition: 0.3s ease-out;
    }
`;

export function Newsletter() {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    // Email value
    const [email, setEmail] = useState('');

    // SUBMIT / API POST CALL
    const postEmailNewsletter = async () => {
        if (email.length === 0) {
            toast.error('Email cannot be blank');
        } else {
            const loadingToast = toast.loading('Loading...');
            const creatorURL = process.env.REACT_APP_CREATOR_URL;
            await axios
                .post(`${creatorURL}/mailingList`, {
                    email,
                    newsletter: true,
                })
                .then(async () => {
                    toast('Thank you for subscribing to our newsletter!', {
                        id: loadingToast,
                        icon: '💙',
                    });
                })
                .catch((err: any) => {
                    toast.error(err.response.data.message, {
                        id: loadingToast,
                    });
                });
        }
    };
    return (
        <>
            <Toaster />
            <Slide bottom>
                <NewsletterContainer>
                    <TextContainer>
                        <IllustrationImage src={newsletter} />
                        <TopText>Join our Newsletter</TopText>
                        <NormalText>
                            We'll always inform you about the latest content, updates, and
                            discounts. Just subscribe to us :)
                        </NormalText>
                        <EmailContainer>
                            <EmailInputField
                                placeholder={'   yourname@mail.com'}
                                required
                                maxLength={50}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button onClick={() => postEmailNewsletter()}>
                                <ButtonText>Join</ButtonText>
                            </Button>
                        </EmailContainer>
                    </TextContainer>
                </NewsletterContainer>
            </Slide>
        </>
    );
}
