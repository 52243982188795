import React from 'react';
import { Footer } from '../nav/Footer';
import { NavBar } from '../nav/NavBar';
import { GetToKnowMore } from './top/GetToKnowMore';
import { MeetTheTeam } from './top/MeetTheTeam';
import { OurMission } from './top/OurMission';

export function AboutUs() {
    return (
        <>
            <NavBar />

            <GetToKnowMore />

            <div
                style={{
                    width: '100%',
                    display: 'wrap',
                    maxWidth: 1800,
                    position: 'relative',
                    flexDirection: 'column',
                }}
            >
                <OurMission />
                <MeetTheTeam />
            </div>

            <Footer />
        </>
    );
}
