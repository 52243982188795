import React from 'react';
import styled from 'styled-components';
import backgroundImage from '../../../assets/images/Homepage_Background.png';
import iphoneImage from '../../../assets/images/timo-profile.png';
import { fonts, device } from '../../../utils/theme.util';
import { AppStoreButtons } from '../../common/AppStoreButtons';
import { BackgroundImage } from '../../common/backgroundImage';

const IphoneImage = styled.img`
    width: 22%;
    z-index: 2;
    display: block;
    top: 15%;
    right: 13%;
    position: absolute;

    @media ${device.monitorS} {
        top: 25%;
        width: 12%;
        right: 25%;
    }

    @media ${device.tabletS} {
        width: 0%;
    }
`;

const IphoneMobileImage = styled.img`
    width: 0%;
    z-index: 2;
    display: block;
    margin-top: 5%;
    right: 12%;
    position: absolute;

    @media ${device.monitorS} {
        top: 20%;
    }

    @media ${device.tabletS} {
        width: 60%;
        z-index: 2;
        display: block;
        top: 0px;
        left: 20%;
        align-self: center;
        position: relative;
    }
`;

const TextContainer = styled.div`
    flex-direction: column;
    diplay: flex;
    margin-left: 4%;
    margin-top: 5%;
    z-index: 3;
    width: 45%;
    // align-self: flex-start;
    align-self: center;
    justify-content: center;
    align-items: center;

    @media ${device.tabletS} {
        margin-top: 0px;
        z-index: 3;
        width: 92%;
        align-self: center;
    }
`;

const TopText = styled.h1`
    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 40px;
    font-weight: 600;
    width: 100%;
    z-index: 3;

    &:focus {
        outline: none;
    }
    diplay: flex;

    @media ${device.tabletS} {
        margin-top: 25%;
        font-size: 36px;
        text-align: center;
        align-self: center;
    }
`;

const SubText = styled.p`
    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 20px;
    font-weight: 200;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 5%;
    z-index: 3;

    &:focus {
        outline: none;
    }
    diplay: flex;

    @media ${device.tabletS} {
        text-align: center;
        align-self: center;
        margin-top: 15px;
        font-size: 18px;
    }
`;

export function CallToAction() {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                marginTop: 70,
                maxWidth: 1800,
                // position: 'relative',
                // backgroundColor: 'red',
                alignSelf: 'center',
                alignItems: 'center',
            }}
        >
            <BackgroundImage src={backgroundImage} style={{ backgroundSize: 'cover' }} />
            <IphoneImage src={iphoneImage} />

            <TextContainer>
                <IphoneMobileImage src={iphoneImage} />

                <Slide left>
                    <TopText>Manage all your payments seamlessly.</TopText>
                    <SubText>
                        Designed for Artists, Influencers, Content Creators, Athletes,
                        Musicians, Writers, and Photographers to guide your audiences to
                        all the places you’d like them to go.
                    </SubText>
                    <AppStoreButtons />
                </Slide>
            </TextContainer>
        </div>
    );
}
