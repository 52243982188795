/* eslint-disable global-require */
import React, { useEffect, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import axios from 'axios';

import { theme, device } from '../utils/theme.util';
import { GlobalStyle } from '../shared-styles/global.styles';
import { BaseRouter } from '../routers/base.router';

require('dotenv').config();

const BaseContainer = styled.div`
    display: flex;
    min-height: 100vh;
    background-color: #04141e;
`;

const CardContainer = styled.div`
    display: flex;

    width: 100%;
    height: 100%
    border-radius: 15px;
    background: #fff;
    overflow: hidden;

    // @media ${device.mobileS} {
    //     align-items: center;
    //     height: 100%;
    //     width: 100%;
    //     border-radius: 0px;
    //     overflow: hidden;
    // }
`;

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export function App(): JSX.Element {
    const myRef = useRef(null);
    const publicIp = require('public-ip');

    const getIpAddress = async () => {
        const newIpAddress = await publicIp.v4();
        return newIpAddress;
    };

    const saveIpAddress = async () => {
        const creatorURL = process.env.REACT_APP_CREATOR_URL;
        await axios
            .post(`${creatorURL}/ip`, {
                ipAddress: await getIpAddress(),
            })
            .then(async (res) => {
                return res;
            })
            .catch((err: any) => {
                return err.response;
            });
    };

    useEffect(() => {
        saveIpAddress();
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <BaseContainer id='main' ref={myRef}>
                <CardContainer>
                    <CardContent>
                        <BaseRouter />
                    </CardContent>
                </CardContainer>
            </BaseContainer>
        </ThemeProvider>
    );
}
