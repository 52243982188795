import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormField } from './FormField';
import { FormOutline, Row } from './FormOutline';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const CheckboxLabel = styled.label`
    font-size: 1rem;
    line-height: 22px;
    display: flex;
    align-items: center;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
`;

const Checkbox = styled.input`
    border-radius: 8px;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin-right: 1rem;

    &:checked {
        background-color: grey;
    }
`;

const Button = styled.div<{ disabled: boolean }>`
    &:hover {
        cursor: pointer;
    }
    border-radius: 8px;
    background-color: #e86e8a;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
    margin-bottom: 8.125rem;
    padding: 14px 26px;

    ${({ disabled }) =>
        disabled &&
        `background-color: lightgrey;
        &:hover {
            cursor: auto;
        }
    `}
`;

const ErrorText = styled.div`
    margin-bottom: 2rem;
    font-size: 18px;
    text-align: center;
    color: red;
    font-weight: bold;
`;

interface Props {
    referringName: string | null;
    phoneNumberWithCountry: string;
    createdUserName: string;
}

const creatorURL = process.env.REACT_APP_CREATOR_URL;

export const Verification: React.FC<Props> = ({
    referringName,
    phoneNumberWithCountry,
    createdUserName,
}) => {
    const history = useHistory();

    const [code, setCode] = useState('');
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState('');

    const disabled = !code || !checked;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const verify = async () => {
        try {
            const res: any = await axios.post(`${creatorURL}/auth/confirm`, {
                code,
                username: phoneNumberWithCountry,
            });

            history.push(`/signup-success?userName=${createdUserName}`);
        } catch (error) {
            console.log(error);
            setError(
                'Error confirming phone number, please make sure you typed in the code correctly.'
            );
        }
    };

    return (
        <>
            <FormOutline referringName={referringName}>
                <Row>
                    <FormField
                        placeholder='Verification Code'
                        label='Enter verification code'
                        value={code}
                        onChange={setCode}
                    />
                </Row>
                <CheckboxLabel>
                    <Checkbox
                        type='checkbox'
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                    />
                    I certify that I am 18 years of age or older, and agree to the Terms
                    of Services and Privacy Policy
                </CheckboxLabel>
                {error && <ErrorText>{error}</ErrorText>}
                <Button disabled={disabled} onClick={!disabled ? verify : undefined}>
                    Create Account
                </Button>
            </FormOutline>
        </>
    );
};
