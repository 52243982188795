interface CountryFlag {
    name: string;
    flag: string;
    shortName: string;
    phoneExtention: string;
    currency: string;
    currencyName: string;
    currencyIcon: string;
}

const Flags: CountryFlag[] = [
    {
        name: 'United States of America',
        flag: 'USA--United_States_of_America.png',
        shortName: 'US',
        phoneExtention: '+1',
        currency: 'USD',
        currencyName: 'United States dollar',
        currencyIcon: '$',
    },
    {
        name: 'Australia',
        flag: 'AUS--Australia.png',
        shortName: 'AUS',
        phoneExtention: '+61',
        currency: 'AUD',
        currencyName: 'Australian dollar',
        currencyIcon: 'A$',
    },
    {
        name: 'Austria',
        flag: 'AUT--Austria.png',
        shortName: 'AUT',
        phoneExtention: '+43',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Belgium',
        flag: 'BEL--Belgium.png',
        shortName: 'BEL',
        phoneExtention: '+32',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Bulgaria',
        flag: 'BGR--Bulgaria.png',
        shortName: 'BGR',
        phoneExtention: '+359',
        currency: 'BGN',
        currencyName: 'Bulgarian lev',
        currencyIcon: 'Лв.',
    },
    {
        name: 'Brazil',
        flag: 'BRA--Brazil.png',
        shortName: 'BRA',
        phoneExtention: '+55',
        currency: 'BRL',
        currencyName: 'Brazilian real',
        currencyIcon: 'R$',
    },
    // {
    //     name: 'Canada',
    //     flag: 'CAN--Canada.png',
    //     shortName: 'CAN',
    //     phoneExtention: '+1',
    //     currency: 'CAD',
    //     currencyName: 'Canadian dollar',
    //     currencyIcon: 'C$',
    // },
    {
        name: 'Switzerland',
        flag: 'CHE--Switzerland.png',
        shortName: 'CHE',
        phoneExtention: '+41',
        currency: 'CHF',
        currencyName: 'Swiss franc',
        currencyIcon: '₣',
    },
    {
        name: 'Cyprus',
        flag: 'CYP--Cyprus.png',
        shortName: 'CYP',
        phoneExtention: '+357',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Czech Republic',
        flag: 'CZE--Czech_Republic.png',
        shortName: 'CZE',
        phoneExtention: '+420',
        currency: 'CZK',
        currencyName: 'Czech koruna',
        currencyIcon: 'Kč',
    },
    {
        name: 'Germany',
        flag: 'DEU--Germany.png',
        shortName: 'DEU',
        phoneExtention: '+49',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Denmark',
        flag: 'DNK--Denmark.png',
        shortName: 'DNK',
        phoneExtention: '+45',
        currency: 'DKK',
        currencyName: 'Danish krone',
        currencyIcon: 'Kr.',
    },
    {
        name: 'Spain',
        flag: 'ESP--Spain.png',
        shortName: 'ESP',
        phoneExtention: '+34',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Estonia',
        flag: 'EST--Estonia.png',
        shortName: 'EST',
        phoneExtention: '+372',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Finland',
        flag: 'FIN--Finland.png',
        shortName: 'FIN',
        phoneExtention: '+358',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'France',
        flag: 'FRA--France.png',
        shortName: 'FRA',
        phoneExtention: '+33',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'United Kingdom',
        flag: 'GBR--United_Kingdom.png',
        shortName: 'GBR',
        phoneExtention: '+44',
        currency: 'GBP',
        currencyName: 'Pound sterling',
        currencyIcon: '£',
    },
    {
        name: 'Gilbraltar',
        flag: 'GIB--Gibraltar.png',
        shortName: 'GIB',
        phoneExtention: '+350',
        currency: 'GIP',
        currencyName: 'Gibraltar pound',
        currencyIcon: '£',
    },
    {
        name: 'Greece',
        flag: 'GRC--Greece.png',
        shortName: 'GRC',
        phoneExtention: '+30',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Hong Kong',
        flag: 'HKG--Hong_Kong_SAR_China.png',
        shortName: 'HKG',
        phoneExtention: '+852',
        currency: 'HKD',
        currencyName: 'Hong Kong dollar',
        currencyIcon: 'HK$',
    },
    {
        name: 'Hungary',
        flag: 'HUN--Hungary.png',
        shortName: 'HUN',
        phoneExtention: '+36',
        currency: 'HUF',
        currencyName: 'Hungarian forint',
        currencyIcon: 'Ft',
    },
    {
        name: 'India',
        flag: 'IND--India.png',
        shortName: 'IND',
        phoneExtention: '+91',
        currency: 'INR',
        currencyName: 'Indian rupee',
        currencyIcon: '₹',
    },
    {
        name: 'Ireland',
        flag: 'IRL--Ireland.png',
        shortName: 'IRL',
        phoneExtention: '+353',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Italy',
        flag: 'ITA--Italy.png',
        shortName: 'ITA',
        phoneExtention: '+39',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Japan',
        flag: 'JPN--Japan.png',
        shortName: 'JPN',
        phoneExtention: '+81',
        currency: 'JPY',
        currencyName: 'Japanese yen',
        currencyIcon: '¥',
    },
    {
        name: 'Liechtenstein',
        flag: 'LIE--Liechtenstein.png',
        shortName: 'LIE',
        phoneExtention: '+423',
        currency: 'CHF',
        currencyName: 'Swiss franc',
        currencyIcon: '₣',
    },
    {
        name: 'Lithuania',
        flag: 'LTU--Lithuania.png',
        shortName: 'LTU',
        phoneExtention: '+370',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Luxemburg',
        flag: 'LUX--Luxembourg.png',
        shortName: 'LUX',
        phoneExtention: '+352',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Latvia',
        flag: 'LVA--Latvia.png',
        shortName: 'LVA',
        phoneExtention: '+371',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Mexico',
        flag: 'MEX--Mexico.png',
        shortName: 'MEX',
        phoneExtention: '+52',
        currency: 'MXN',
        currencyName: 'Mexican peso',
        currencyIcon: 'Mex$',
    },
    {
        name: 'Malta',
        flag: 'MLT--Malta.png',
        shortName: 'MLT',
        phoneExtention: '+356',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Malaysia',
        flag: 'MYS--Malaysia.png',
        shortName: 'MYS',
        phoneExtention: '+60',
        currency: 'MYR',
        currencyName: 'Malaysian ringgit',
        currencyIcon: 'RM',
    },
    {
        name: 'Netherlands',
        flag: 'NLD--Netherlands.png',
        shortName: 'NLD',
        phoneExtention: '+31',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Norway',
        flag: 'NOR--Norway.png',
        shortName: 'NOR',
        phoneExtention: '+47',
        currency: 'NOK',
        currencyName: 'Norwegian krone',
        currencyIcon: 'kr',
    },
    {
        name: 'New Zealand',
        flag: 'NZL--New_Zealand.png',
        shortName: 'NZL',
        phoneExtention: '+64',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Poland',
        flag: 'POL--Poland.png',
        shortName: 'POL',
        phoneExtention: '+48',
        currency: 'PLN',
        currencyName: 'Polish zloty',
        currencyIcon: 'zł',
    },
    {
        name: 'Portugal',
        flag: 'PRT--Portugal.png',
        shortName: 'PRT',
        phoneExtention: '+351',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Romania',
        flag: 'ROU--Romania.png',
        shortName: 'ROU',
        phoneExtention: '+40',
        currency: 'RON',
        currencyName: 'Romanian leu',
        currencyIcon: 'lei',
    },
    {
        name: 'Singapore',
        flag: 'SGP--Singapore.png',
        shortName: 'SGP',
        phoneExtention: '+65',
        currency: 'SGD',
        currencyName: 'Singapore dollar',
        currencyIcon: 'S$',
    },
    {
        name: 'Slovakia',
        flag: 'SVK--Slovakia.png',
        shortName: 'SVK',
        phoneExtention: '+421',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Slovenia',
        flag: 'SVN--Slovenia.png',
        shortName: 'SVN',
        phoneExtention: '+386',
        currency: 'EUR',
        currencyName: 'European euro',
        currencyIcon: '€',
    },
    {
        name: 'Sweden',
        flag: 'SWE--Sweden.png',
        shortName: 'SWE',
        phoneExtention: '+46',
        currency: 'SEK',
        currencyName: 'Swedish krona',
        currencyIcon: 'kr',
    },
];
export default Flags;
