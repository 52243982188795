import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
    label: string;
    placeholder: string;
    value: string;
    setValue: (v: string) => void;
    large?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
`;

const Label = styled.p`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #5a7184;
    margin-bottom: 12px;
`;

const inputStyles = css`
    border-radius: 0.5rem;
    padding: 12px;
    border: 1px solid lightgrey;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #183b56;

    &:focus {
        outline: none !important;
        border: 1px solid dimgrey;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: lightgrey;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: lightgrey;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: lightgrey;
    }
`;

const InputBox = styled.input`
    ${inputStyles}
    max-width: 350px;
`;

const TextAreaBox = styled.textarea`
    ${inputStyles}
    width: 100%;
    height: 140px;
    resize: none;
`;

export function Input({ label, placeholder, value, setValue, large }: Props) {
    return (
        <Container>
            <Label>{label}</Label>
            {large ? (
                <TextAreaBox
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={placeholder}
                />
            ) : (
                <InputBox
                    type='text'
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder={placeholder}
                />
            )}
        </Container>
    );
}
