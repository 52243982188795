import React from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

interface Props {
    questionText: string;
    answerText: string;
    expanded: boolean;
    setExpanded: () => void;
}

const Container = styled.div`
    border-radius: 8px;
    padding: 24x;
    border: 1px solid #e5eaf4;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    margin: 0px 16px 16px;

    user-select: none;
    &:hover {
        cursor: pointer;
    }
`;

const Header = styled.div<{ expanded: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${({ expanded }) => expanded && `color: #1565D8`}
`;

const QuestionText = styled.span`
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
    padding: 32px;
`;

const AnswerText = styled.div`
    padding: 8px 32px 16px;
    font-size: 16px;
    line-height: 32px;
    color: #5a7184;
`;

export function Question({ questionText, answerText, expanded, setExpanded }: Props) {
    return (
        <Container onClick={setExpanded}>
            <Header expanded={expanded}>
                <QuestionText>{questionText}</QuestionText>
                <QuestionText>{expanded ? '-' : '+'}</QuestionText>
            </Header>
            <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
                <AnswerText>{ReactHtmlParser(answerText)}</AnswerText>
            </AnimateHeight>
        </Container>
    );
}
