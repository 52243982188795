import React from 'react';
import styled from 'styled-components';
import { device, fonts, theme } from '../../utils/theme.util';
import pig from '../../assets/images/sign-up/Pig.png';
import { DATA, Item } from '../products/wallet/components/Stats';
import { Stats } from './Stats';
import { NavBar } from '../nav/NavBar';

const Container = styled.div`
    max-width: 800px;
    margin: 0 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${fonts.primary};
`;

const Pig = styled.img`
    margin-top: 60px;
    @media ${device.tabletL} {
        margin-top: 40px;
    }
    height: 338px;
    width: 338px;
`;

const Title = styled.div`
    text-align: center;
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.5rem;
    color: #1a2633;
    margin-bottom: 1rem;
`;

const Subtitle = styled.div`
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
    color: ${theme.gray500};
    margin-bottom: 2rem;
`;

const Form = styled.div`
    width: 100%;
`;

const Label = styled.div`
    color: #1a2633;
    margin-bottom: 32px;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 800;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    margin-bottom: 2rem;

    @media ${device.mobileL} {
        flex-direction: column;
    }
`;

interface Props {
    referringName: string | null;
    children: React.ReactNode;
}

export const FormOutline: React.FC<Props> = ({ referringName, children }) => {
    return (
        <>
            <NavBar />
            <Container>
                <Pig src={pig} />
                <Title>
                    {referringName
                        ? `You’ve been invited by ${referringName} to try Söshme!`
                        : `Join the platform that helps creators fuel their business.`}
                </Title>
                <Subtitle>
                    It’s free to start earning money on Söshme. Join the community of
                    thousands of Creators that have more than ‘just a side hustle.’{' '}
                    <a target='blank' href='/' style={{ color: '#E86E8A' }}>
                        Learn more
                    </a>
                </Subtitle>
                <Form>
                    <br />
                    <br />
                    {/* <Label>Required fields have asterisk: *</Label> */}
                    {children}
                </Form>
            </Container>
            <Stats />
        </>
    );
};
