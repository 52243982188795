import React from 'react';
import styled from 'styled-components';
import analysis from '../../../assets/images/phone-analysis.png';
import mobAnalysis from '../../../assets/images/phone-analysis-mob.png';

import { SubText, TopText } from '../../../shared-styles/home.styles';
import { device } from '../../../utils/theme.util';

const PhoneImage = styled.img`
    display: flex;
    z-index: 3;
    margin-top: 5%;

    width: 80%;
    margin-left: 10%;
    margin-right: 10%;

    @media ${device.tabletS} {
        width: 0%;
    }
`;

const MobImage = styled.img`
    display: flex;
    z-index: 3;
    margin-top: 5%;

    width: 0%;

    @media ${device.tabletS} {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
`;

const MainTextView = styled.div`
    display: flex;
    flex: 1,
    flex-direction: row;
    font-weight: 300
    p {
        font-weight: 600
    }
`;

export function AppAnalysis() {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');

    return (
        <>
            <Slide bottom>
                <TopText style={{ marginTop: '10%' }}>
                    The best decision you’ll make for your business
                </TopText>
                <MainTextView>
                    <SubText>
                        Sign up for Söshme today to start managing your
                        <span style={{ fontWeight: 'bold' }}> entire </span>
                        business.
                    </SubText>
                </MainTextView>
            </Slide>
            <Fade>
                <PhoneImage src={analysis} />
                <MobImage src={mobAnalysis} />
            </Fade>
        </>
    );
}
