import React from 'react';
import styled from 'styled-components';
import { SöshmeLogo } from '../landing-page/components/SoshmeLogo';
import { device } from '../../utils/theme.util';
import facebook from '../../assets/images/social-icons/Facebook.png';
import linkedIn from '../../assets/images/social-icons/LinkedIn.png';
import instagram from '../../assets/images/social-icons/Instagram.png';
import twitter from '../../assets/images/social-icons/Twitter.png';

const OuterMostContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    width: 100%;
`;

const OuterContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 180px;
    padding-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;

    @media ${device.tabletS} {
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 75px;
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media ${device.tabletS} {
        flex-direction: column;
        align-self: center;
        justify-content: center;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media ${device.tabletS} {
        margin-top: 48px;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 12px;
    }
`;

const LinkList = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 120px;

    @media ${device.tabletS} {
        margin-left: 0px;
        text-align: center;
    }
`;

const LinkSectionHeader = styled.div`
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #183b56;
    margin-bottom: 30px;
`;

const Link = styled.a`
    color: ${({ theme }) => theme.gray500};
    text-decoration: none;
    font-size: 16px;
    transition: all 0.3s ease;
    margin-bottom: 24px;

    &:hover {
        color: ${({ theme }) => theme.blue};
    }
`;

const SocialsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 36px;
`;

const SocialIcon = styled.img`
    height: 50px;
    display: block;
    justify-content: center;
    align-self: center;
    align-items: center;

    transform: translate(0%);
    transition: 0.3s ease-out;

    &:hover {
        transform: translate(0%, -30%);
        transition: 0.3s ease-out;
    }
`;

const Copyright = styled.div`
    text-align: center;
    font-size: 13px;
    color: ${({ theme }) => theme.gray500};
`;

const LogoContainer = styled.div`
    @media ${device.tabletS} {
        height: 0px;
        opacity: 0;
    }
`;

export function Footer() {
    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');
    return (
        <OuterMostContainer>
            <OuterContainer>
                <Fade>
                    <InnerContainer>
                        <LeftContainer>
                            <LogoContainer>
                                <SöshmeLogo />
                            </LogoContainer>
                            <SocialsContainer>
                                <a
                                    href='https://www.facebook.com/soshmeapp/'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <SocialIcon src={facebook} />
                                </a>

                                <a
                                    href='https://www.twitter.com/joinsoshme'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <SocialIcon src={twitter} />
                                </a>

                                <a
                                    href='https://www.instagram.com/soshmeapp/'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <SocialIcon src={instagram} />
                                </a>

                                <a
                                    href='https://www.linkedin.com/company/paysplit'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    <SocialIcon src={linkedIn} />
                                </a>
                            </SocialsContainer>
                        </LeftContainer>
                        <RightContainer>
                            <LinkList>
                                <LinkSectionHeader>Company</LinkSectionHeader>
                                <Link href='/about-us' rel='noopener noreferrer'>
                                    About
                                </Link>
                                <Link
                                    href='https://www.notion.so/paysplit/Terms-of-Service-Agreement-9be82bed56724c96b304ec9388200d33'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Terms
                                </Link>
                                <Link
                                    href='https://www.notion.so/paysplit/Privacy-Policy-05b77e5381e144fc9d09c38fe25190bc'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Privacy
                                </Link>
                            </LinkList>
                            <LinkList>
                                <LinkSectionHeader>More</LinkSectionHeader>
                                <Link
                                    href='https://substack.com/profile/38565263-soshme?r=myl5b&utm_campaign=profile&utm_medium=web&utm_source=copy'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Blog
                                </Link>
                                <Link
                                    href='https://climate.stripe.com/4ZBlzM'
                                    rel='noopener noreferrer'
                                    target='_blank'
                                >
                                    Climate
                                </Link>
                                <Link href='/faq' rel='noopener noreferrer'>
                                    FAQ
                                </Link>
                                <Link href='/contact-us' rel='noopener noreferrer'>
                                    Contact Us
                                </Link>
                            </LinkList>
                        </RightContainer>
                    </InnerContainer>
                    <Copyright>
                        Copyright &copy; 2022 PaySplit, Inc. Crafted with love.
                    </Copyright>
                </Fade>
            </OuterContainer>
        </OuterMostContainer>
    );
}
