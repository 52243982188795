import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { LandingPage } from '../components/landing-page/LandingPage';
import { AboutUs } from '../components/about-us/AboutUs';
import { ContactUs } from '../components/contact-us/ContactUs';
import { FAQ } from '../components/faq/FAQ';
import { NotFound } from '../components/not-found/NotFound';
import { ProfileProduct } from '../components/products/profile/ProfileProduct';
import { WalletProduct } from '../components/products/wallet/WalletProduct';
import { BusinessProduct } from '../components/products/business/BusinessProduct';
import { WebSignUp } from '../components/web-sign-up/WebSignUp';
import { SignUpSuccess } from '../components/web-sign-up/SignUpSuccess';

export function BaseRouter(): JSX.Element {
    return (
        <BrowserRouter>
            <Switch>
                <Route
                    path='/signup'
                    render={() => {
                        return <WebSignUp />;
                    }}
                />
                <Route
                    path='/signup-success'
                    render={() => {
                        return <SignUpSuccess />;
                    }}
                />
                <Route
                    path='/about-us'
                    render={() => {
                        return <AboutUs />;
                    }}
                />
                <Route
                    path='/faq'
                    render={() => {
                        return <FAQ />;
                    }}
                />
                <Route
                    path='/contact-us'
                    render={() => {
                        return <ContactUs />;
                    }}
                />
                <Route
                    path='/products/profile'
                    render={() => {
                        return <ProfileProduct />;
                    }}
                />
                <Route
                    path='/products/wallet'
                    render={() => {
                        return <WalletProduct />;
                    }}
                />
                <Route
                    path='/products/business'
                    render={() => {
                        return <BusinessProduct />;
                    }}
                />
                <Route
                    path='/'
                    exact
                    render={() => {
                        return <LandingPage />;
                    }}
                />
                <Route
                    render={() => {
                        return <NotFound />;
                    }}
                />
            </Switch>
        </BrowserRouter>
    );
}
