export const FAQs = {
    general: [
        {
            question: 'How do I create a Söshme Account?',
            answer: '<span>Söshme is available to download on the Google Play and Apple App Store. Once downloaded, you can easily create an account by opening the app and clicking on the “Signup” option which will direct you to an onboarding flow.</span>',
        },
        {
            question: 'What information is required to create your Söshme Account?',
            answer: '<span>The only information we require to create your Söshme account is an email address, phone number, password, first name, last name, and a preferred username. Optional information you can enter into your profile includes information such as your location, biography, eWallets, social media, custom links, affiliate brand links & discounts, and website.</span>',
        },
        {
            question: 'Which countries is Söshme available?',
            answer: '<span>Söshme is available for download in the United States, Australia, Austria, Belgium, Bulgaria, Brazil, Canada, Switzerland, Cyprus, Czech Republic, Germany, Denmark, Spain, Estonia, Finland, France, United Kingdom, Gibraltar, Greece, Hong Kong, Hungary, India, Ireland, Italy, Japan, Liechtenstein, Lithuania, Luxembourg, Latvia, Mexico, Malta, Malaysia, Netherlands, Norway, Poland, Portugal, Romania, Singapore, Slovakia, Slovenia, and Sweden.</span>',
        },
        {
            question: 'What information does Söshme Collect?',
            answer: '<div>We collect different kinds of information. Some of it is personally identifiable and some is non-identifying or aggregated. Here are the types of information we collect or receive:</div><ul style="padding-left:40px"><li>Account & Profile Information</li><li>Billing Information</li><li>Device Information</li><li>Usage Information</li><li>Information & Content you provide</li><li>Network & Connections</li><li>Information about transactions made on our Platform</li></ul><br><div>For more detailed information, please visit our <a href="https://www.notion.so/Privacy-Policy-05b77e5381e144fc9d09c38fe25190bc">Privacy Policy.</a></div>',
        },
        {
            question: 'How does Söshme protect my data?',
            answer: 'Data protection is something that we at Söshme take very seriously, our main concern is to keep your data free from any harm. All your data is secured and encrypted using <a href="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard">AES-256</a> encryption. We do not sell or offer your information to any 3rd party.',
        },
        {
            question: 'How do I request my data?',
            answer: 'You can fill out a data request form in the app, simply tap <b><i>Settings > Request my data.</b></i> Once the request form is submitted and received, we will be contacting you via email to send you access to download your data. It may take up to 3 business days for your request to be completed. ',
        },
        {
            question: 'How do I delete my account?',
            answer: 'At any time you can opt-out of our service, all you have to do is tap <b><i>Settings > Advanced > Delete account.</i></b> Once an account is deleted, it can no longer be activated or recovered. ',
        },
    ],
    profile: [
        {
            question:
                'How do I add, edit or delete my Social Platforms, Wallets, Custom Links or Embedded Videos?',
            answer: 'Once an account is created, you will be directed to setup your Söshme portal. Your profile portal will initially be empty, you can tap on each section to view and add your Platforms, Wallets, Custom Links and Videos.',
        },
        {
            question: 'How many Links, Platforms, Wallets can I add?',
            answer: 'At Söshme our mission is to empower creators, therefore we do not charge or limit the quantity of Links, Platforms, and Wallets that you can add. You can also add multiple accounts you may have in a given Platform such as your personal & business Instagram accounts.',
        },
        {
            question: 'How can I find and share my Söshme Portal?',
            answer: 'You can find your Söshme Portal by accessing your browser and going to your Söshme URL at<br>https://soshme.me/<b>YourUsername</b>.<br><br>Also, we have different options available in which you can easily share your profile using the app:<br><br><i style="font-weight: bold; color: #0075FF">Share it via text, email or airdrop:</i><br>In the app you can easily share your profile by tapping the share button located on the top of your Home section, this option allows you to share your profile via text, airdrop, or messaging platform.<br><br><i style="font-weight: bold; color: #0075FF">Share it via QR Codes:</i><br>QR Code option is also available and located at the top of the Home section, making it easier to share your profile by having another device scan your QR code with their device’s camera or QR Code scanner.<br><br><i style="font-weight: bold; color: #0075FF">Share it via Near Field Communication (NFC):</i><br>Once you tap the share button located in the top of your Home section, on the bottom left of the page, there is a button named “Add NFC devices”. This option allows you to seamlessly connect your personal NFC device with your Söshme Portal, that way you can share your Profile Portal with just a tap.',
        },
        {
            question: 'My Platforms and Wallets are not working. What should I do?',
            answer: 'When adding certain Platforms and Wallets you are presented with two options:<ul style="padding-left:40px"><li>To add the Platform or Wallet by providing your username</li><li>Or by proving the Platform or Wallet custom link</li></ul><br>If you are providing the Platform or Wallet custom link, make sure to enable the custom link toggle above the input section. If you checked the information imputed, but the issue still remains, please report the issue by going into <b>Settings > Additional > Submit Bugs or Feedback.</b> Once the report is received, our team will contact you within 24 hours. ',
        },
    ],
    wallet: [
        {
            question: 'How do I create a Söshme Wallet?',
            answer: 'You can opt-in to create a Söshme Wallet by going to the app, locating the navigation bar tab on the Wallet icon, to then be prompted to create your Söshme Wallet. You can choose to create an Individual or a Business Söshme wallet, once your Söshme Wallet is created, you will unlock all Monetization features and capabilities, and be able to instantly receive payments in your Söshme Portal.',
        },
        {
            question: 'What information is required to create a Söshme Wallet?',
            answer: 'In order to create a Söshme Wallet we collect and verify information needed to meet Know Your Customer (KYC) and compliance requirements for payments – which may vary based on local or regional compliance requirements. Minimum requirements are: Legal Name, date of birth, address, merchant category code, id information, credit card or bank account information. ',
        },
        {
            question: 'Which countries is the Söshme Wallet supported?',
            answer: 'Currently, the Söshme Wallet capabilities are only supported and available in the United States of America.<br>Our team is actively working to enable the Söshme Wallet in other regions, and soon it will be available in Australia, Austria, Belgium, Bulgaria, Brazil, Canada, Switzerland, Cyprus, Czech Republic, Germany, Denmark, Spain, Estonia, Finland, France, United Kingdom, Gibraltar, Greece, Hong Kong, Hungary, India, Ireland, Italy, Japan, Liechtenstein, Lithuania, Luxembourg, Latvia, Mexico, Malta, Malaysia, Netherlands, Norway, Poland, Portugal, Romania, Singapore, Slovakia, Slovenia, and Sweden.',
        },
        {
            question: 'How much does it cost to create a Söshme Wallet?',
            answer: 'There are no subscription fees in order to create your Söshme Wallet. With the Söshme Wallet you can pay as you go, meaning that we charge 6.25% + 55¢ per transaction which includes transaction and service fees. See the total and additional fee breakdown below:<br><br>Transaction fee:  3.25% + 55¢<br>Söshme Service fee: 3%<br><br>Other Additional Fees:<br>+1% for international cards<br>+1% if currency conversion is required<br>$5 monthly cap',
        },
        {
            question: 'How many currencies can I accept with the Söshme Wallet?',
            answer: 'With the Söshme Wallet you can accept over 135+ currencies. For more information checkout <a href="https://www.notion.so/Söshme/Countries-of-Operation-295ac106471a4e2e8e7e9356abca810f">our countries of operation and currencies</a> guide.',
        },
        {
            question: 'Does Söshme Wallet provide Tax Compliance?',
            answer: 'Yes. We provide 1099 U.S. Tax reporting capabilities to all Söshme Wallet users.<br><br>A 1099 form is used to report various types of income and payment transactions. For example, a marketplace may issue 1099s to summarize earnings for independent contractors, while a software platform may issue 1099s to summarize their customers’ payment transaction volume. In addition, you’re required to file a 1099 form for each person or business from whom you withheld taxes, regardless of the amount of the payment.<br><br>There are several types of 1099 forms. We’ve summarized the most common forms that you may need to consider. We recommend consulting a tax advisor to determine your specific tax filing and reporting requirements.',
        },
        {
            question: '1099-NEC',
            answer: 'Use the 1099-NEC form to report non-employee compensation.<br>The account must meet all of the following criteria in the previous calendar year:<ul style="padding-left:40px"><li>Based in the U.S. or a U.S. taxpayer</li><li>More than 600 USD in payments</li>',
        },
        {
            question: '1099-MISC',
            answer: 'Use the 1099-MISC form to report other forms of payments made in the course of your business.<br>The account must meet all of the following criteria in the previous calendar year:<ul style="padding-left:40px"><li>Based in the U.S. or a U.S. taxpayer</li><li>More than 600 USD in payments or 10 USD in royalties</li>',
        },
        {
            question: '1099-K',
            answer: 'Use the 1099-K form to report payment transactions.<br>The account must meet all of the following criteria in the previous calendar year:<ul style="padding-left:40px"><li>Based in the U.S. or a U.S. taxpayer</li><li>More than 20,000 USD in gross volume</li><li>More than 200 transactions</li>',
        },
    ],
};
