import React from 'react';
import styled from 'styled-components';
import { BackgroundImage } from './backgroundImage';
import background from '../../assets/images/top-triangle.png';
import { AppStoreButtons } from './AppStoreButtons';
import { device } from '../../utils/theme.util';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    margin-top: 0rem;
    width: 100%;

    @media ${device.laptopS} {
        flex-direction: column-reverse;
    }
`;

const Phone1 = styled.img`
    width: 350px;
    height: 707px;
    margin-top: 7rem;

    @media ${device.laptopS} {
        width: 50%;
        height: auto;
    }
`;

const TextContainer = styled.div`
    max-width: 560px;

    @media ${device.laptopS} {
        margin: 1rem 2rem 0rem;
    }
`;

const TitleText = styled.h1`
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 36px;
    line-height: 68px;
    letter-spacing: 0.2px;
    color: ${({ theme }) => theme.white};
    text-align: left;

    @media ${device.laptopS} {
        color: ${({ theme }) => theme.black};
        text-align: center;
    }
`;

const SubText = styled.p<{ buttonsSpace: boolean }>`
    font-size: 20px;
    line-height: 36px;
    color: ${({ theme }) => theme.white};
    margin-bottom: ${({ buttonsSpace }) => (buttonsSpace ? '30px' : '2rem')};

    @media ${device.laptopS} {
        color: ${({ theme }) => theme.black};
        margin-bottom: 1.5rem;
        text-align: center;
    }
`;

interface Props {
    title: string;
    subtitle: string;
    imageSrc: string;
    // eslint-disable-next-line react/require-default-props
    buttonsSpace?: boolean;
}

export function ProductTopSection({
    title,
    subtitle,
    imageSrc,
    buttonsSpace = true,
}: Props) {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    return (
        <>
            <BackgroundImage style={{ zIndex: 2, maxHeight: 1000 }} src={background} />
            <Container style={{ zIndex: 3 }}>
                <Slide left>
                    <TextContainer>
                        <TitleText>{title}</TitleText>
                        <SubText buttonsSpace={buttonsSpace}>{subtitle}</SubText>
                        <AppStoreButtons />
                    </TextContainer>
                </Slide>
                <Phone1 src={imageSrc} />
            </Container>
        </>
    );
}
