import React from 'react';
import Load from 'react-loader-spinner';
import { theme } from '../../utils/theme.util';

export const Loader = () => {
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 2,
            }}
        >
            <Load type='TailSpin' color={theme.red} height={50} width={50} />
        </div>
    );
};
