import React from 'react';
import styled from 'styled-components';
import { fonts, device } from '../../../utils/theme.util';
import { TopText } from '../../../shared-styles/home.styles';

import iustin from '../../../assets/images/creators/iustin.png';
import indigo from '../../../assets/images/creators/indigo.png';
import jonluc from '../../../assets/images/creators/jon-luc.png';
import integrations from '../../../assets/images/NewIntegration.png';
import mobIntegrations from '../../../assets/images/mob-integration.png';

const Divider = styled.div`
    height: 1px;
    background-color: #e5eaf4;
    display: flex;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 40px;

    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
`;

const CreatorsContainer = styled.div`
    width: 80%;
    display: inline-flex;

    margin-top: 20px;

    justify-content: space-around;
    align-self: center;

    flex-direction: row;

    // background-color: red;

    @media ${device.tabletS} {
        flex-direction: column;
        width: 80%;
        align-self: center;
        margin-top: 0px;
    }
`;

const CreatorContainer = styled.a`
    width: 31%;
    display: flex;

    position: relative;
    align-self: center;

    @media ${device.tabletS} {
        width: 100%;
        position: relative;
        margin-top: 20px;
    }

    transform: translate(0%);
    transition: 0.3s ease-out;

    &:hover {
        transform: translate(0%, -5%);
        transition: 0.3s ease-out;
    }
`;

const CreatorImage = styled.img`
    // height: 100%;
    width: 100%;
    align-self: center;
    position: relative;

    // @media ${device.tabletS} {
    //     height: 20%;
    // }
`;

const Title = styled.p`
    font-famiy: ${fonts.primary};
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    position: absolute;

    left: 6%;

    top: 57%;
    width: 90%;

    @media ${device.tabletS} {
        font-size: 24px;
    }
`;

const SubTitle = styled.p`
    font-famiy: ${fonts.primary};
    color: #fff;
    font-size: 16px;
    font-weight: 200;
    position: absolute;

    // text-align: center;
    // align-self: center;
    left: 6%;

    top: 75%;
    width: 90%;

    @media ${device.mobileS} {
    }
`;

const IntegrationsImage = styled.img`
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;

    margin-top: 60px;
    @media ${device.tabletS} {
        width: 0%;
    }
`;

const IntegrationsMobileImage = styled.img`
    width: 0%;

    @media ${device.tabletS} {
        margin-top: 40px;
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
`;

export function MeetCreators() {
    // const [animate, setAnimate] = useState(false);
    // eslint-disable-next-line global-require

    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');

    return (
        <div
            style={{
                backgroundColor: '#fff',
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
            }}
        >
            <Divider />
            <Fade>
                <TopText>Meet Creators</TopText>
            </Fade>
            <CreatorsContainer>
                <CreatorContainer
                    href='https://www.instagram.com/p/CL994zNlZgl/'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <CreatorImage src={iustin} />
                    <Title>Future of Fashion</Title>
                    <SubTitle>
                        Meet Iustin, a fashion designer who first began selling his custom
                        graphic tees on DePop.
                    </SubTitle>
                </CreatorContainer>
                <CreatorContainer
                    href='https://www.instagram.com/p/CMFwQ_zld3z/'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <CreatorImage src={indigo} />
                    <Title>Photography</Title>
                    <SubTitle>
                        Meet indigo, a freelance photographer leveraging Söshme to
                        further her business.
                    </SubTitle>
                </CreatorContainer>

                <CreatorContainer
                    href='https://www.instagram.com/p/CMSZrTilsCm/'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <CreatorImage src={jonluc} />
                    <Title>Gamers & Streamers</Title>
                    <SubTitle>
                        Meet Jon-Luc, he live-streams on Twitch, and shares gameplay...
                    </SubTitle>
                </CreatorContainer>
            </CreatorsContainer>

            <Fade>
                <IntegrationsImage src={integrations} />
            </Fade>
            <Fade>
                <IntegrationsMobileImage src={mobIntegrations} />
            </Fade>
        </div>
    );
}
