import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils/theme.util';

export type DataType = { image: string; title: string; subtitle: string }[];

const TextRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 38px;

    @media ${device.tabletS} {
        flex-direction: column;
    }
`;

const IconImage = styled.img`
    height: 78px;
    width: 78px;
`;

const TextRowTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;

    @media ${device.tabletS} {
        margin-left: 0px;
        margin-top: 1rem;
    }
`;

const RowTitleText = styled.p`
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: #183b56;

    @media ${device.tabletS} {
        text-align: center;
    }
`;

const RowSubText = styled.p`
    font-size: 16px;
    line-height: 28px;
    color: #5a7184;

    @media ${device.tabletS} {
        text-align: center;
    }
`;

export const InfoRow = ({
    index,
    DATA,
    className,
}: {
    index: number;
    DATA: DataType;
    className?: string;
}) => {
    return (
        <TextRow className={className}>
            <IconImage src={DATA[index].image} />
            <TextRowTextContainer>
                <RowTitleText>{DATA[index].title}</RowTitleText>
                <RowSubText>{DATA[index].subtitle}</RowSubText>
            </TextRowTextContainer>
        </TextRow>
    );
};
