import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Flags from './flags';
import { FormField } from './FormField';
import { FormOutline, Row } from './FormOutline';
import { Loader } from './Loader';
import { checkPassword, decryptText, textToJSON } from './utils';
import { Verification } from './Verification';

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const Button = styled.div<{ disabled: boolean }>`
    &:hover {
        cursor: pointer;
    }
    border-radius: 100px;
    background-color: #e86e8a;
    width: 327px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
    margin-bottom: 5rem;
    user-select: none;

    ${({ disabled }) =>
        disabled &&
        `background-color: lightgrey;
        &:hover {
            cursor: auto;
        }
    `}

    &:hover {
        transform: translate(0%, -5%);
        transition: 0.3s ease-out;
    }
`;

const CountryCodeButton = styled.select`
    border-radius: 8px;
    background-color: #e86e8a;
    color: #000;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    padding: 0 1.5rem;
    align-items: center;
    margin-right: 1rem;
`;

const ErrorText = styled.div`
    margin-bottom: 2rem;
    font-size: 18px;
    text-align: center;
    color: red;
    font-weight: bold;
`;

const creatorURL = process.env.REACT_APP_CREATOR_URL;

export const WebSignUp = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCodeObj, setCountryCodeObj] = useState(Flags[0]);
    const [verificationMode, setVerificationMode] = useState(false);
    const [usernameIsTaken, setUsernameIsTaken] = useState(false);
    const [usernameAvailable, setUsernameAvailable] = useState(false);
    const [referringName, setReferringName] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const params = useLocation<any>();
    const searchParams = new URLSearchParams(params.search as any);
    const referringUserName = searchParams.get('userName');

    const disabled =
        (!firstName ||
            !lastName ||
            !username ||
            !password ||
            !checkPassword(password) ||
            !phoneNumber) &&
        usernameAvailable;

    useEffect(() => {
        const fetchReferringUser = async () => {
            setLoading(true);
            const res: any = await axios.get(
                `${creatorURL}/username/${referringUserName}`
            );
            const paysplitToken = res.data.data['PaySplit-Token'];
            const decryptedAccount = await decryptText(paysplitToken);
            const decryptObj = textToJSON(decryptedAccount);

            const userData = decryptObj[0];
            setReferringName(userData.firstName);
            setLoading(false);
        };

        if (referringUserName) {
            fetchReferringUser();
        }
    }, [referringUserName]);

    const signUp = async () => {
        try {
            const res: any = await axios.post(`${creatorURL}/auth/signup`, {
                name: firstName + ' ' + lastName,
                givenName: firstName,
                familyName: lastName,
                email,
                phoneNumber: countryCodeObj.phoneExtention + phoneNumber,
                username: countryCodeObj.phoneExtention + phoneNumber,
                referral: referringUserName ?? undefined,
                preferredUsername: username,
                password,
            });

            setVerificationMode(true);
        } catch (error) {
            console.log(error);
            setError(
                'Error signing up, please try again. Also, please make sure an account with the given phone number does not already exist.'
            );
        }
    };

    const checkUsernameAvaliability = (username: string) => {
        setUsernameAvailable(false);
        setUsernameIsTaken(false);
        axios
            .post(`${creatorURL}/check/username`, {
                username: username.toLowerCase(),
            })
            .then(() => {
                setUsernameAvailable(true);
            })
            .catch((error) => {
                setUsernameIsTaken(true);
            });
    };

    if (loading) {
        return <Loader />;
    }

    if (verificationMode) {
        return (
            <Verification
                referringName={referringName}
                phoneNumberWithCountry={countryCodeObj.phoneExtention + phoneNumber}
                createdUserName={username}
            />
        );
    }

    return (
        <>
            <FormOutline referringName={referringName}>
                <Row>
                    <FormField
                        placeholder='First name'
                        label='First name*'
                        value={firstName}
                        onChange={setFirstName}
                    />
                    <FormField
                        placeholder='Last name'
                        label='Last name*'
                        value={lastName}
                        onChange={setLastName}
                    />
                </Row>
                <Row>
                    <FormField
                        placeholder='Email'
                        label='Email*'
                        value={email}
                        onChange={setEmail}
                    />
                </Row>
                <Row>
                    <FormField
                        placeholder='Username'
                        label='Username*'
                        value={username}
                        onChange={setUsername}
                        error={
                            usernameIsTaken
                                ? `The username is already taken.`
                                : usernameAvailable
                                ? `Username available`
                                : undefined
                        }
                        errorGreen={usernameAvailable}
                        onBlur={() => checkUsernameAvaliability(username)}
                    />
                </Row>
                <Row>
                    <FormField
                        placeholder='Password'
                        label='Password*'
                        value={password}
                        onChange={setPassword}
                        error={
                            password && !checkPassword(password)
                                ? 'The given password is too weak.'
                                : ''
                        }
                        hint='The password must contain: at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.'
                    />
                </Row>
                <Row>
                    <FormField
                        placeholder='Phone number'
                        label='Phone number*'
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        leftComponent={
                            <CountryCodeButton
                                onChange={(e) =>
                                    setCountryCodeObj(Flags[Number(e.target.value)])
                                }
                            >
                                {Flags.map((f, i) => (
                                    <option key={i} value={i}>
                                        {f.phoneExtention}
                                    </option>
                                ))}
                            </CountryCodeButton>
                        }
                    />
                </Row>
                <ButtonWrapper>
                    {error && <ErrorText>{error}</ErrorText>}
                    <Button onClick={!disabled ? signUp : undefined} disabled={disabled}>
                        Send Code
                    </Button>
                </ButtonWrapper>
            </FormOutline>
        </>
    );
};
