import React from 'react';
import styled from 'styled-components';
import icon1 from '../../../../assets/images/products/wallet/Wallet-icon-1.png';
import icon2 from '../../../../assets/images/products/wallet/Wallet-icon-2.png';
import icon3 from '../../../../assets/images/products/wallet/Wallet-icon-3.png';
import icon4 from '../../../../assets/images/products/wallet/Wallet-icon-4.png';
import { device } from '../../../../utils/theme.util';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    padding-top: 300px;
    margin: 0 auto;

    @media ${device.tabletS} {
        flex-direction: column;
        padding-top: 150px;
    }
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 220px;

    @media ${device.tabletS} {
        margin-bottom: 2rem;
    }
`;

const Image = styled.img`
    width: 80px;
    height: auto;
    margin-bottom: 0.5rem;
`;

const TitleText = styled.p`
    font-weight: bold;
    font-size: 1.875rem;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 0.75rem;
`;

const SubText = styled.p`
    font-size: 0.875rem;
    line-height: 28px;
    color: #5a7184;
    text-align: center;
`;

export const DATA = [
    {
        image: icon1,
        title: '1099',
        subtitle: 'Ensuring tax compliant, Söshme issues 1099-NEC, 1099-MISC, 1099-K.',
    },
    {
        image: icon2,
        title: 'AES-256',
        subtitle: 'All your data is secured using AES-256 encryption. ',
    },
    {
        image: icon3,
        title: '135+',
        subtitle: 'Currencies and payment methods supported.',
    },
    {
        image: icon4,
        title: '35+',
        subtitle: 'Countries with local acquiring, optimizing acceptance rates.',
    },
];

export const Item = ({ index }: { index: number }) => {
    return (
        <ItemContainer>
            <Image src={DATA[index].image} />
            <TitleText>{DATA[index].title}</TitleText>
            <SubText>{DATA[index].subtitle}</SubText>
        </ItemContainer>
    );
};

export function Stats() {
    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');

    return (
        <Fade>
            <Container>
                {DATA.map((_, i) => (
                    <Item index={i} />
                ))}
            </Container>
        </Fade>
    );
}
