import React from 'react';
import styled from 'styled-components';

import { device } from '../../../utils/theme.util';

import soshmeLogo from '../../../assets/images/soshme-logo.png';

const Link = styled.a`
    text-decoration: none;
`;

const SoshmeImage = styled.img`
    height: 100%;
    display: block;
    justify-content: left;
    align-self: left;
    align-items: left;
`;

export function SöshmeLogo() {
    return (
        <Link href='/'>
            <div
                style={{
                    flexDirection: 'row',
                    display: 'flex',
                    height: 45,
                    alignSelf: 'center',
                }}
            >
                <SoshmeImage src={soshmeLogo} />
            </div>
        </Link>
    );
}
