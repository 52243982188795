import React, { useState } from 'react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import styled, { css } from 'styled-components';
import creator3 from '../../../../assets/images/products/profile/Emmi-Profile.png';
import creator4 from '../../../../assets/images/products/profile/max-profile.png';
import creator2 from '../../../../assets/images/products/profile/Creator-2.png';
import { device } from '../../../../utils/theme.util';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 85%;
    margin: 150px auto 0px;

    @media ${device.laptopS} {
        margin: 100px auto 0px;
        flex-direction: column-reverse;
        justify-content: center;
    }
`;

const CreatorImage = styled.img`
    width: 350px;
    height: 707px;

    @media ${device.laptopS} {
        width: 70%;
        height: auto;
    }
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 480px;
    margin-left: 100px;

    @media ${device.laptopS} {
        margin-bottom: 3rem;
        align-items: center;
        margin-left: 0px;
    }
`;

const Bar = styled.div`
    width: 28px;
    height: 5px;
    border-radius: 2px;
    background-color: #344563;

    @media ${device.laptopS} {
        display: none;
    }
`;

const TitleText = styled.p`
    font-weight: 600;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 0.5rem;
    margin-top: 2rem;
`;

const SubText = styled.p`
    font-size: 16px;
    line-height: 28px;
    color: #5a7184;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 70px;
    align-items: center;

    @media ${device.laptopS} {
        margin-top: 2rem;
    }
`;

const ArrowContainer = styled.button<{ disabled: boolean }>`
    width: 56px;
    height: 56px;
    border-radius: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #183b56;
    background-color: ${({ theme }) => theme.white};
    ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

const Arrow = css`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #183b56;
    box-sizing: border-box;
    &:after,
    &:before {
        content: '';
        box-sizing: border-box;
    }
    width: 15px;
    height: 15px;
    border-width: 3px 3px 0 0;
    border-style: solid;
    margin: 10px;
    &:before {
        right: 0;
        top: -2px;
        position: absolute;
        height: 3px;
        box-shadow: inset 0 0 0 32px;
        transform: rotate(-45deg);
        width: 18px;
        transform-origin: right top;
    }
`;

const LeftArrow = styled.i`
    ${Arrow}
    transform: rotate(-135deg);
`;

const RightArrow = styled.i`
    ${Arrow}
    transform: rotate(45deg);
`;

const ScreensText = styled.p`
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #183b56;
`;

export function JoinOthers() {
    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');

    const [value, setValue] = useState(0);

    const onChange = (value: number) => {
        setValue(value);
    };

    return (
        <Fade>
            <Container>
                <Carousel value={value} onChange={onChange}>
                    <CreatorImage src={creator3} />
                    <CreatorImage src={creator4} />
                    <CreatorImage src={creator2} />
                </Carousel>
                <RightContainer>
                    <Bar />
                    <TitleText>Join hundreds of other creators</TitleText>
                    <SubText>
                        Many creators already experience the benefits Söshme brings them in their
                        online careers. Check them out for yourself.
                    </SubText>
                    <ButtonContainer>
                        <ArrowContainer
                            onClick={() => onChange(value - 1)}
                            disabled={value === 0}
                        >
                            <LeftArrow />
                        </ArrowContainer>
                        <ArrowContainer
                            style={{ marginLeft: 20, marginRight: 30 }}
                            onClick={() => onChange(value + 1)}
                            disabled={value === 2}
                        >
                            <RightArrow />
                        </ArrowContainer>
                        <ScreensText>{value + 1}/3 screens</ScreensText>
                    </ButtonContainer>
                </RightContainer>
            </Container>
        </Fade>
    );
}
