import React from 'react';
import styled from 'styled-components';
import { device, fonts } from '../../../utils/theme.util';

import wave from '../../../assets/images/Wave.png';
import why from '../../../assets/images/illustration-why.png';

const WaveImage = styled.img`
    width: 100%;
    display: flex;
    z-index: 4;
    margin-top: -5%;

    // background-color: blue;

    // margin-top: ;
    @media ${device.tabletS} {
        margin-top: -45%;
    }
`;

const WhyPaySplitContainer = styled.div`
    width: 100%;
    display: flex;
    align-self: center;

    margin-top: -6%;
    padding-bottom: 10%;
    z-index: 1;

    // position: relative;
    justify-content: flex-end;

    background: linear-gradient(to bottom, #f8fbff, #fff);

    // margin-top: ;

    @media ${device.tabletS} {
        margin-top: -10%;
    }
`;

const IllustrationImage = styled.img`
    width: 40%;
    z-index: 4;
    display: block;
    top: 30%;
    left: 10%;
    position: absolute;
    align-self: flex-start;

    @media ${device.tabletS} {
        width: 80%;
        margin-left: 0%;
        margin-right: 10%;
        margin-top: 20%;
        margin-bottom: 10%;
        z-index: 2;
        display: block;
        align-self: center;
        position: relative;
        // background-color: red;
    }
`;

const TextContainer = styled.div`
    flex-direction: column;
    diplay: flex;
    margin-right: 10%;
    margin-top: 5%;
    z-index: 2;
    width: 38%;
    align-self: center;

    // background-color: red;

    // position: relative;

    @media ${device.tabletS} {
        margin-top: 0px;

        align-self: center;

        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
`;

const TopText = styled.h1`
    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 36px;
    font-weight: 600;

    text-align: left;

    margin-top: 30%;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 100%;

    @media ${device.tabletS} {
        font-size: 30px;
        text-align: center;
    }
`;

const NormalText = styled.p`
    font-famiy: ${fonts.primary};
    color: #5a7184;
    background-color: transparent;
    font-size: 18px;
    font-weight: 200;
    flex: 1;
    text-align: left;
    align-self: center;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 100%;

    margin-top: 20px;

    @media ${device.tabletS} {
        text-align: center;
    }
`;

const Divider = styled.div`
    // height: 1px;
    // background-color: #e5eaf4;
    display: flex;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 40px;
    border-bottom: 1px dashed #e5eaf4;

    width: 100%;
`;

const OuterContainer = styled.div`
    width: 100%;
    display: wrap;
    position: relative;

    @media ${device.tabletS} {
        margin-top: 45%;
    }
`;

export function WhyPaySplit() {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    return (
        <OuterContainer>
            <WaveImage src={wave} />
            <WhyPaySplitContainer>
                <TextContainer>
                    <IllustrationImage src={why} />
                    <Slide right>
                        <TopText>Why Söshme?</TopText>
                        <NormalText>
                            Söshme’s mission is to empower Creators to freely transact in
                            today&apos;s global payment ecosystem by removing barriers to
                            connection.
                        </NormalText>
                        <Divider />
                    </Slide>
                </TextContainer>
            </WhyPaySplitContainer>
        </OuterContainer>
    );
}
