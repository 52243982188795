import React from 'react';
import styled from 'styled-components';
import { device, theme } from '../../utils/theme.util';

const Container = styled.div`
    flex: 1;
    &:not(:last-of-type) {
        margin-right: 3.25rem;
    }

    @media ${device.mobileL} {
        &:not(:last-of-type) {
            margin-bottom: 2rem;
        }
        margin-right: 0rem !important;
    }
`;

const Label = styled.div`
    color: #1a2633;
    margin-bottom: 0.875rem;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 800;
`;

const Hint = styled.div`
    color: ${theme.gray500};
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 1rem;
`;

const TextInput = styled.input`
    border: 1px solid #8c8a9a;
    border-radius: 8px;
    padding: 15px 9px;
    width: 100%;
    font-size: 16px;
`;

const Error = styled.div<{ green?: boolean }>`
    color: ${({ green }) => (green ? '#4BB543' : '#d30000')};
    font-size: 1rem;
    line-height: 1.375rem;
    margin-top: 0.75rem;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

interface Props {
    label: string;
    hint?: string;
    placeholder: string;
    error?: string;
    value: string;
    onChange: (val: string) => void;
    style?: any;
    leftComponent?: React.ReactNode;
    onBlur?: () => void;
    errorGreen?: boolean;
}

export const FormField: React.FC<Props> = ({
    label,
    hint,
    placeholder,
    error,
    value,
    onChange,
    style,
    leftComponent,
    onBlur,
    errorGreen,
}) => {
    return (
        <Container style={style}>
            <Label>{label}</Label>
            {hint && <Hint>{hint}</Hint>}
            <Row>
                {leftComponent ?? null}
                <TextInput
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => onChange(e.target.value)}
                    type={placeholder === 'Password' ? 'password' : undefined}
                    onBlur={onBlur}
                />
            </Row>
            {error && <Error green={errorGreen}>{error}</Error>}
        </Container>
    );
};
