import React from 'react';
import styled from 'styled-components';
import { NavBar } from '../../nav/NavBar';
import wave from '../../../assets/images/products/profile/Wave.svg';
import { Features } from './components/Features';
import { JoinOthers } from './components/JoinOthers';
import { GrowYourBrand } from './components/GrowYourBrand';
import { Footer } from '../../nav/Footer';
import phone1 from '../../../assets/images/products/profile/Creator-2.png';
import { ProductTopSection } from '../../common/ProductTopSection';

const GradientContainer = styled.div`
    margin-top: 150px;
    width: 100%;
    position: relative;
`;

const Arch = styled.img`
    width: 100%;
    position: absolute;
    top: -20px;
    left: 0px;
    right: 0px;
`;

const Gradient = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #f8fbff 5.56%, rgba(250, 251, 251, 0.0001) 100%);
`;

export function ProfileProduct() {
    return (
        <>
            <NavBar />
            <ProductTopSection
                title='Made by creators for creators'
                subtitle='It’s never been easier for your audiences to find you online.
                            Link all your social media, wallets, and crypto wallet addresses!'
                imageSrc={phone1}
            />
            <GradientContainer>
                <Arch src={wave} />
                <Gradient>
                    <Features />
                    <JoinOthers />
                    <GrowYourBrand />
                    <Footer />
                </Gradient>
            </GradientContainer>
        </>
    );
}
