import styled from 'styled-components';
import { device } from '../../utils/theme.util';

export const BackgroundImage = styled.img`
    width: 100%;
    display: block;
    margin-top: 60px;
    z-index: 0;
    top: 20px;
    left: 0;
    position: absolute;

    @media ${device.monitorS} {
        width: 0%;
    }

    @media ${device.laptopS} {
        width: 0%;
    }
`;
