import React, { useState } from 'react';
import styled from 'styled-components';
import { BackgroundImage } from '../common/backgroundImage';
import { NavBar } from '../nav/NavBar';
import background from '../../assets/images/contactus/Background.png';
import itguyimage from '../../assets/images/contactus/it-guy.png';
import { device } from '../../utils/theme.util';
import { Input } from './components/Input';
import { Footer } from '../nav/Footer';
const { WebClient } = require('@slack/web-api');

const token = 'xoxb-883684340048-2192089422117-BbasgUvmeOK5gmyWOxchKrk3';
const web = new WebClient(token);

const ITGuyImage = styled.img`
    position: absolute;
    right: 80px;
    top: 140px;
    width: 258px;
    height: 392px;

    @media ${device.laptopS} {
        display: none;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;
    width: 50%;
    z-index: 1;

    @media ${device.laptopS} {
        margin-top: 100px;
        width: 80%;
    }
`;

const TitleText = styled.div`
    font-size: 46px;
    line-height: 68px;
    text-align: center;
    letter-spacing: 0.2px;
    margin-bottom: 24px;
    color: ${({ theme }) => theme.white};
    z-index: 1;

    @media ${device.laptopS} {
        color: ${({ theme }) => theme.black};
    }
`;

const SubText = styled.div`
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: ${({ theme }) => theme.white};
    z-index: 1;

    @media ${device.laptopS} {
        color: ${({ theme }) => theme.black};
    }
`;

const FormBox = styled.div`
    z-index: 2;
    background-color: ${({ theme }) => theme.white};
    padding: 3rem;
    border-radius: 1rem;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 1px 1px 15px -10px #000000;
    display: flex;
    flex-direction: column;

    @media ${device.laptopS} {
        margin-left: 2rem;
        margin-right: 2rem;
    }
`;

const Disclaimer = styled.p`
    font-size: 16px;
    line-height: 28px;
    color: #5a7184;
`;

const SubmitButton = styled.div`
    &:hover {
        cursor: pointer;
    }

    padding: 12px 40px;
    border-radius: 0.5rem;
    background-color: #e86e8a;
    color: ${({ theme }) => theme.white};
    font-size: 16px;
    box-shadow: 1px 1px 15px -10px #000000;
    align-self: flex-start;
    margin-top: 2rem;
`;

const SubmittedText = styled.p`
    text-align: center;
    color: ${({ theme }) => theme.blue};
    font-size: 16px;
    margin-top: 1.5rem;
`;

const ErrorText = styled(SubmittedText)`
    color: ${({ theme }) => theme.red};
`;

export function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const submit = async () => {
        if (!name || !email || !phone || !message) {
            setError('Not all required fields were filled out');
            return;
        }

        setError('');

        const conv = 'contact-us-form';
        try {
            await web.chat.postMessage({
                text: `New Message from ${name}:\nEmail: ${email}\nPhone: ${phone}\nMessage: ${message}`,
                channel: conv,
            });

            setSubmitted(true);

            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
        } catch (e) {
            setError('An unknown error occurred');
        }
    };

    return (
        <>
            <NavBar />
            <BackgroundImage src={background} />
            <ITGuyImage src={itguyimage} />
            <Container>
                <TextContainer>
                    <TitleText>Contact our team</TitleText>
                    <SubText>
                        If you need our help, have questions about how to use the
                        platform, or are experiencing technical difficulties, please do
                        not hesitate to contact us.
                    </SubText>
                </TextContainer>
            </Container>
            <FormBox>
                <Input
                    value={name}
                    setValue={setName}
                    placeholder='John Doe'
                    label='Full Name*'
                />
                <Input
                    value={email}
                    setValue={setEmail}
                    placeholder='john.doe@example.com'
                    label='Email*'
                />
                <Input
                    value={phone}
                    setValue={setPhone}
                    placeholder='+1 (323) 888 9256'
                    label='Mobile*'
                />
                <Input
                    value={message}
                    setValue={setMessage}
                    placeholder='Type your message...'
                    label='Your Message*'
                    large
                />
                <Disclaimer>
                    By submitting this form you agree to our{' '}
                    <a href='https://www.notion.so/paysplit/Terms-of-Service-Agreement-9be82bed56724c96b304ec9388200d33'>
                        terms and conditions
                    </a>{' '}
                    and our
                    <a href='https://www.notion.so/paysplit/Privacy-Policy-05b77e5381e144fc9d09c38fe25190bc'>
                        {' '}
                        Privacy Policy
                    </a>{' '}
                    which explains how we may collect, use and disclose your personal
                    information including to third parties.
                </Disclaimer>
                <SubmitButton onClick={submit}>Submit</SubmitButton>
                {submitted && (
                    <SubmittedText>
                        Thank you for contacting us. We will get back to you shortly.
                    </SubmittedText>
                )}
                {error && <ErrorText>{error}</ErrorText>}
            </FormBox>
            <Footer />
        </>
    );
}
