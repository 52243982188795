import React from 'react';
import styled from 'styled-components';
import img1 from '../../../assets/images/aboutus/group1.png';
import img2 from '../../../assets/images/aboutus/group2.png';
import { device } from '../../../utils/theme.util';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 450px;

    @media ${device.tabletS} {
        margin-top: 200px;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3rem;

    @media ${device.tabletS} {
        flex-direction: column;
    }
`;

const MissionText = styled.div`
    font-size: 80px;
    text-align: left;
    flex: 0.4;
    letter-spacing: -2.5px;
    line-height: 80px;
    margin-bottom: 2rem;

    @media ${device.tabletS} {
        font-size: 44px;
        line-height: 44px;
    }
`;

const SubText = styled.div`
    flex: 0.6;
    margin: 2rem;
    font-size: 26px;

    @media ${device.tabletS} {
        margin: 1.5rem 0rem;
        font-size: 18px;
    }
`;

const ImagesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Image1 = styled.img`
    width: 29%;
`;

const Image2 = styled.img`
    width: 69%;
`;

export function OurMission() {
    // eslint-disable-next-line global-require
    const Fade = require('react-reveal/Fade');

    return (
        <Fade>
            <Container>
                <TextWrapper>
                    <MissionText>Our mission is to make your life easier.</MissionText>
                    <SubText>
                        Söshme’s mission is to empower Creators to freely transact in
                        today&apos;s global payment ecosystem by removing barriers for
                        connection.
                        {'\n'}
                        Söshme’s vision is to build the preferred global payment channel
                        for individuals while simultaneously working with the businesses
                        that are sending them payments.
                    </SubText>
                </TextWrapper>
                <ImagesWrapper>
                    <Image1 src={img1} />
                    <Image2 src={img2} />
                </ImagesWrapper>
            </Container>
        </Fade>
    );
}
