import React from 'react';
import styled from 'styled-components';
import lock from '../../../../assets/images/products/profile/Lock.png';
import graph from '../../../../assets/images/products/profile/Graph.png';
import instant from '../../../../assets/images/products/profile/Instant.png';
import shield from '../../../../assets/images/products/profile/Shield.png';
import creator from '../../../../assets/images/products/wallet/withdraw.png';
import { ProductFeatures } from '../../../common/ProductFeatures';
import { device } from '../../../../utils/theme.util';

const DATA = [
    {
        image: graph,
        title: 'Manage your business',
        subtitle:
            'Accept payments, donations, and keep track of income streams in one place.',
    },
    {
        image: shield,
        title: 'Tax compliance',
        subtitle:
            'Ensuring your business is tax compliant, Söshme issues annual 1099 forms.',
    },
    {
        image: lock,
        title: 'Data Security',
        subtitle: 'All payments are encrypted with AES-256 encryption.',
    },
    {
        image: instant,
        title: 'Instant transfer',
        subtitle: 'We process funds within 2-3 business days at fastest availability',
    },
];

const Container = styled.div`
    width: 80%;
    margin: 0 auto;

    @media ${device.tabletS} {
        width: 90%;
    }
`;

export function Features() {
    return (
        <Container>
            <ProductFeatures DATA={DATA} creatorImage={creator} reversed />
        </Container>
    );
}
