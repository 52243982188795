import React from 'react';
import styled from 'styled-components';
import { fonts, device } from '../../../utils/theme.util';

import feature1 from '../../../assets/images/feature-1.png';
import feature2 from '../../../assets/images/feature-2.png';
import feature3 from '../../../assets/images/feature-3.png';
import {
    LARGE_PADDING,
    MEDIUM_PADDING,
    SMALL_PADDING,
    SubText,
    TopText,
} from '../../../shared-styles/home.styles';

const MainContainer = styled.div`
    flex-direction: column;
    diplay: flex;
    z-index: 3;
    background-color: #fff;
    margin-top: 200px;

    @media ${device.laptopM} {
        top: 30%;
    }

    z-index: 10;
    @media ${device.tabletS} {
        margin-top: 60px;
    }
`;

const FeaturesContainer = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 3;
    // background-color: red;
    width: 80%;

    margin-top: 50px;

    margin-bottom: -100px;

    margin-left: 10%;
    margin-right: 10%;
    align-self: center;

    justify-content: space-around;

    @media ${device.tabletS} {
        flex-direction: column;
        justify-content: center;
        align-self: center;
        margin-bottom: 0px;
    }
`;

const FeatureContainer = styled.div`
    display: flex;
    width: 33%;
    flex-direction: column;

    border-width: 3px;
    border-color: black;

    @media ${device.tabletS} {
        width: 92%;
        align-self: center;
    }
`;

const FeatureImage = styled.img`
    height: 30%;
    display: inline-flex;
    align-self: center;

    margin-top: ${MEDIUM_PADDING};
    @media ${device.tabletS} {
        height: 150px;
    }
`;

const FeatureTitle = styled.h2`
    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;

    margin-top: ${LARGE_PADDING};

    &:focus {
        outline: none;
    }
    diplay: flex;

    @media ${device.tabletS} {
    }
`;

const FeatureSubText = styled.p`
    font-family: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 16px;
    font-weight: 200;
    width: 100%;
    align-self: center;
    text-align: center;

    margin-top: ${MEDIUM_PADDING};
    margin-bottom: ${LARGE_PADDING};

    width: 80%;
    // margin-right: -${SMALL_PADDING};

    &:focus {
        outline: none;
    }
    diplay: flex;

    @media ${device.tabletS} {
    }
`;

export function OurFeatures() {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    return (
        <Slide bottom>
            <MainContainer>
                {/* <Slide bottom> */}
                <TopText>The Only Link You’ll Ever Need</TopText>
                <SubText>
                    Add your Söshme Link to your social media bios, share in your email
                    signature, and directly send to your audiences. We recommend starting
                    with your Instagram & TikTok bios. This gives your followers a chance
                    to connect with you across multiple platforms.
                </SubText>
                {/* </Slide> */}

                <FeaturesContainer>
                    <FeatureContainer>
                        <FeatureImage src={feature1} />
                        <FeatureTitle>Easy access</FeatureTitle>
                        <FeatureSubText>
                            Guide your audiences to the places you’d like them to go.
                        </FeatureSubText>
                    </FeatureContainer>

                    <FeatureContainer>
                        <FeatureImage src={feature2} />
                        <FeatureTitle>Centralize your income streams</FeatureTitle>
                        <FeatureSubText>
                            Manage your income streams between brands, agencies, and
                            audiences in one place
                        </FeatureSubText>
                    </FeatureContainer>

                    <FeatureContainer>
                        <FeatureImage src={feature3} />
                        <FeatureTitle>Total control</FeatureTitle>
                        <FeatureSubText>
                            Take full control of your data. Söshme will help you do just
                            that. Anywhere. Anytime.
                        </FeatureSubText>
                    </FeatureContainer>
                </FeaturesContainer>
            </MainContainer>
        </Slide>
    );
}
