import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../utils/theme.util';
import { PillButton } from './PillButton';
import { PricingCard } from './PricingCard';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 250px;
`;

const Pill = styled.p`
    background-color: #36b37e19;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #36b37e;
    text-transform: uppercase;
    padding: 5px 18px;
    border-radius: 100px;
    margin-bottom: 2.5rem;
`;

const TitleText = styled.p`
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 0.25rem;
    max-width: 750px;

    @media ${device.laptopS} {
        margin-left: 2rem;
        margin-right: 2rem;
    }
`;

const SubCss = css`
    font-size: 18px;
    line-height: 31px;
    text-align: center;
    color: #5a7084;
    max-width: 750px;
`;

const SubText = styled.span`
    ${SubCss}

    @media ${device.laptopS} {
        margin-left: 2rem;
        margin-right: 2rem;
    }
`;

const BoldSubText = styled.span`
    ${SubCss}
    font-weight: bold;
`;

const CardRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 5rem 0;

    @media ${device.laptopS} {
        flex-direction: column;
        align-items: center;
    }
`;

const DATA = [
    {
        color: '#36B37E',
        title: 'Söshme Profile',
        costText: 'Free',
        costSubText: 'for life',
        description:
            'The easiest way to guide your audiences to the places you’d like them to go.',
        children: <PillButton outlined>Get started</PillButton>,
    },
    {
        color: '#FAAD13',
        title: 'Söshme Wallet',
        costText: '6.25% + 55¢',
        costSubText: 'total fees',
        description: (
            <>
                Access a complete payment platform with simple, pay-as-you-go pricing.
                <br />3.25% + 55¢ transaction fees<br />3% Söshme service fees
                <br />+1% for international cards<br />+1% if currency conversion is
                required<br />$5 monthly cap
            </>
        ),
        children: null,
    },
    {
        color: '#1565D8',
        title: 'Söshme for Business',
        costText: 'Beta',
        costSubText: 'subscribe for our beta trial',
        description:
            'Collaborate with creators to represent your brand. Designed for small businesses and enterprises.',
        children: <PillButton outlined={false}>Join Waitlist</PillButton>,
    },
];

export function Pricing() {
    return (
        <Container>
            <Pill>Our Pricing</Pill>
            <TitleText>Pricing built for businesses of all sizes</TitleText>
            <SubText>
                <BoldSubText>Get started with a Söshme wallet for free,</BoldSubText>{' '}
                try out our platform for an unlimited period of time. Explore our cost and
                services.
            </SubText>
            <CardRow>
                {DATA.map((d) => (
                    <PricingCard {...d} />
                ))}
            </CardRow>
        </Container>
    );
}
