import React from 'react';
import styled from 'styled-components';
import { ProductTopSection } from '../../common/ProductTopSection';
import { Footer } from '../../nav/Footer';
import { NavBar } from '../../nav/NavBar';
import creatorImage from '../../../assets/images/products/wallet/elliot-profile.png';
import illustration from '../../../assets/images/products/wallet/Illustration 2.png';
import { Features } from './components/Features';
import wave from '../../../assets/images/products/profile/Wave.svg';
import { Stats } from './components/Stats';
import { Subscription } from './components/Subscription';
import { WeNever } from './components/WeNever';
import { Pricing } from './components/Pricing';
import { device } from '../../../utils/theme.util';

const Container = styled.div`
    width: 100%;
`;

const GradientContainer = styled.div`
    margin-top: 150px;
    width: 100%;
    position: relative;
`;

const Arch = styled.img`
    width: 100%;
    position: absolute;
    top: -20px;
    left: 0px;
    right: 0px;
`;

const Gradient = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #f8fbff 5.56%, rgba(250, 251, 251, 0.0001) 100%);
`;

const Solid = styled.div`
    width: 100%;
    background-color: #f8fbff;
    display: flex;
    flex-direction: column;
`;

const IllustrationImage = styled.img`
    width: 513px;
    height: 388px;
    margin-left: auto;
    margin-right: 56px;
    padding-bottom: 56px;

    @media ${device.laptopS} {
        display: none;
    }
`;

export function WalletProduct() {
    return (
        <>
            <NavBar />
            <ProductTopSection
                title='Everything you need to know about your creator business in one place.'
                subtitle='Designed for Influencers, Content Creators, Athletes, Musicians, Writers, and Photographers to guide your audiences to the places you’d like them to go.
'
                imageSrc={creatorImage}
                buttonsSpace={false}
            />
            <Container>
                <Features />
                <GradientContainer>
                    <Arch src={wave} />
                    <Gradient>
                        <Stats />
                        <Subscription />
                        <WeNever />
                    </Gradient>
                </GradientContainer>
                <GradientContainer>
                    <Arch src={wave} />
                    <Solid>
                        <Pricing />
                        <IllustrationImage src={illustration} />
                    </Solid>
                </GradientContainer>
            </Container>
            <Footer />
        </>
    );
}
