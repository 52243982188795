import React from 'react';
import styled from 'styled-components';
import { device } from '../../utils/theme.util';
import apple from '../../assets/images/button-app.png';
import play from '../../assets/images/button-play.png';

const ButtonsContainer = styled.div`
    flex-direction: row;
    display: flex;
    z-index: 3;
    @media ${device.laptopS} {
        align-self: center;
        justify-content: center;
    }
`;

const AppleImage = styled.img`
    height: 60px;
    z-index: 3;

    transform: translate(0%);
    transition: 0.3s ease-out;

    &:hover {
        transform: translate(0%, -30%);
        transition: 0.3s ease-out;
    }
    @media ${device.laptopS} {
        height: 35px;
    }
`;

const GoogleImage = styled.img`
    height: 60px;
    z-index: 3;
    margin-left: 2rem;

    transform: translate(0%);
    transition: 0.3s ease-out;

    &:hover {
        transform: translate(0%, -30%);
        transition: 0.3s ease-out;
    }
    @media ${device.laptopS} {
        height: 35px;
    }
`;

const ProductHuntContainer = styled.a`
    align-self: flex-start;
    justify-content: flex-start;

    @media ${device.laptopS} {
        align-self: center;
        justify-content: center;
    }
`;

const ProductHuntImage = styled.img`
    height: 60px;
    z-index: 3;

    margin-top: 20px;
    transform: translate(0%);
    transition: 0.3s ease-out;

    &:hover {
        transform: translate(0%, -30%);
        transition: 0.3s ease-out;
    }
    @media ${device.laptopS} {
        height: 35px;
        align-self: center;
        justify-content: center;
        width: 100%;
    }
`;

export function AppStoreButtons() {
    return (
        <>
            <ButtonsContainer>
                <a
                    href='https://apps.apple.com/us/app/paysplit/id1547157801'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <AppleImage src={apple} />
                </a>
                {/* <div style={{ width: 15, display: 'inline-flex' }} /> */}
                <a
                    href='https://play.google.com/store/apps/details?id=com.paysplit.app'
                    rel='noopener noreferrer'
                    target='_blank'
                >
                    <GoogleImage src={play} />
                </a>
            </ButtonsContainer>
            <ProductHuntContainer
                href='https://www.producthunt.com/posts/soshme?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-soshme'
                target='_blank'
            >
                <ProductHuntImage
                    src='https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=330156&theme=dark'
                    alt='Söshme - Empowering The Modern Creator | Product Hunt'
                />
            </ProductHuntContainer>
        </>
    );
}
