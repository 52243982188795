/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styled from 'styled-components';
import { BackgroundImage } from '../../common/backgroundImage';
import backgroundImage from '../../../assets/images/Hero.svg';
import { fonts } from '../../../utils/theme.util';

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 14%;
    margin-left: auto;
    margin-right: auto;
    padding: 0rem 2rem;
    z-index: 1;
`;

const TitleText = styled.h1`
    text-align: center;
    font-family: ${fonts.primary};
    font-size: 44px;
    color: ${({ theme }) => theme.black};
    margin-bottom: 2rem;
`;

const AboutUsText = styled.h3`
    color: #295E85;
`;

const SubTitleText = styled.p`
    font-size: 22px;
    color: ${({ theme }) => theme.gray600};
    text-align: center;
    max-width: 750px;
`;

export function GetToKnowMore() {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                marginTop: 70, // to go below the nav bar
                maxWidth: 1800,
                // position: 'relative',
                // backgroundColor: 'red',
                alignSelf: 'center',
                alignItems: 'center',
            }}
        >
            <BackgroundImage src={backgroundImage} />
            <TextWrapper>
                <TitleText>
                    Get to know more{'\n'}
                    <AboutUsText>about us</AboutUsText>
                </TitleText>
                <SubTitleText>
                    We are a startup engaged in the passion economy, industry, and
                    business. We're ready to help you improve your business
                    performance with our experienced team.
                </SubTitleText>
            </TextWrapper>
        </div>
    );
}
