import React from 'react';
import styled from 'styled-components';
import elements from '../../assets/images/not-found/Elements.png';
import fourzerofour from '../../assets/images/not-found/404.png';
import { NavBar } from '../nav/NavBar';
import { device } from '../../utils/theme.util';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ImageContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FourZeroFourImage = styled.img`
    width: 385px;
    height: 295px;

    @media ${device.tabletS} {
        width: 50vw;
        height: 37.5vw;
    }
`;

const ElementsImage = styled.img<{ right?: boolean }>`
    width: 110px;
    height: 110px;
    margin-${({ right }) => (right ? 'left' : 'right')}: -2.5rem;
    z-index: 2;

    @media ${device.tabletS} {
        width: 70px;
        height: 70px;
        margin-${({ right }) => (right ? 'left' : 'right')}: -1rem;
    }
`;

const TitleText = styled.p`
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #1f1d2b;
    margin-top: 6px;
    text-align: center;
    margin: 0px 1.5rem;

    @media ${device.tabletS} {
        max-width: 100vw;
        font-size: 28px;
        line-height: 36px;
    }
`;

const SubText = styled.p`
    font-size: 24px;
    line-height: 40px;
    color: #64646c;
    text-align: center;
    margin-top: 24px;
    max-width: 600px;
    margin: 24px 1.5rem 0px 1.5rem;

    @media ${device.tabletS} {
        max-width: 100vw;
        font-size: 20px;
        line-height: 30px;
    }
`;

export function NotFound() {
    return (
        <>
            <NavBar />
            <Container>
                <Content>
                    <ImageContent>
                        <ElementsImage src={elements} />
                        <FourZeroFourImage src={fourzerofour} />
                        <ElementsImage src={elements} right />
                    </ImageContent>
                    <TitleText>Something is wrong</TitleText>
                    <SubText>
                        The page you are looking for was moved, removed, renamed or might
                        have never existed!
                    </SubText>
                </Content>
            </Container>
        </>
    );
}
