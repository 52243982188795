import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif, Arial, Helvetica !important;
    }

    button {
        cursor: pointer;
        border-top: none;
        border: none;
    }
`;

export const CardDropshadow = css`
    // shadow-opacity: 0.1;
    // shadow-radius: 4px;
    // shadow-color: ${({ theme }) => theme.gray400};
    // shadow-offset: 0px 0px;
    // border-width: 0.5px;
    // border-color: ${({ theme }) => theme.white};

    shadow-opacity: 1;
    // shadow-radius: 4px;
    shadow-color: ${({ theme }) => theme.gray400};
    box-shadow: 0px 0px 10px #efeaea;
    // shadow-offset: 0px 0px;
    border-width: 0px;
    border-color: ${({ theme }) => theme.lightBlue};
`;
