import React from 'react';
import styled from 'styled-components';
import { device } from '../../../utils/theme.util';

interface Props {
    title: string;
    subtitle: string;
    // eslint-disable-next-line react/require-default-props
    image?: any;
    onClick: () => void;
    selected: boolean;
}

const Container = styled.div<Partial<Props>>`
    border: 1px solid grey;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 0px 16px;

    &:hover {
        cursor: pointer;
    }

    @media ${device.laptopS} {
        margin: 16px;
        width: 100%;
        margin-top: 2rem;
    }

    ${({ selected }) =>
        selected
            ? `
        border: 2px solid black;
    `
            : 'margin: 1px 17px;'}
`;

const Image = styled.img`
    width: 100px;
    height: 100px;
`;

const TitleText = styled.p`
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-top: 12px;
`;

const SubText = styled.p`
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #5a7184;
    margin-top: 12px;
`;

export function Category({ title, subtitle, image, onClick, selected }: Props) {
    return (
        <Container onClick={onClick} selected={selected}>
            <Image src={image} />
            <TitleText>{title}</TitleText>
            <SubText>{subtitle}</SubText>
        </Container>
    );
}
