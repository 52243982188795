import React, { useState } from 'react';
import styled from 'styled-components';
import { AlertBanner } from '@thumbtack/thumbprint-react';
import { NavButton } from '../../shared-styles/home.styles';
import { device, size } from '../../utils/theme.util';
import { SöshmeLogo } from '../landing-page/components/SoshmeLogo';
import burger from '../../assets/images/Hamburger-Icon.png';
import { MobileNav } from './MobileNav';

const HeaderWrapper = styled.div`
    width: 100%;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 999;
    position: relative;
    top: 0px;
`;

const WarningWrapper = styled.div`
    width: 100%;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 90;
    position: relative;
    top: 0;
`;

const Header = styled.div`
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
`;

const LinksContainer = styled.div`
    flex-direction: row;
    align-self: center;
    align-items: center;
    width: 70%;
    justify-content: flex-end;
    display: flex;

    @media ${device.laptopS} {
        display: none;
    }
`;

const ContactUsButton = styled.a`
    padding: 0.75rem 1.25rem;
    background-color: #e86e8a;
    color: white;
    font-size: 16px;
    font-weight: 400;
    border-radius: 1.5rem;
    margin-left: 0.75rem;
    text-decoration: none;

    &:hover {
        cursor: pointer;

        transform: translate(0%, -5%);
        transition: 0.3s ease-out;
    }
`;

const Hamburger = styled.img`
    display: flex;
    width: 28px;
    height: 28px;

    @media (min-width: ${size.laptopS}) {
        display: none;
    }
`;

const Dropdown = styled.div`
    position: absolute;
    top: 54px;
    padding: 0.25rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const DropdownItem = styled.a`
    &:hover {
        background-color: #d35678;
        cursor: pointer;
        color: ${({ theme }) => theme.white};
    }

    padding: 0.25rem 1.25rem;
    margin: 0.25rem 0rem;
    display: flex;
    flex: 1;
    border-radius: 0.5rem;
    opacity: 0.87;
    text-decoration: none;
    color: ${({ theme }) => theme.black};
`;

export function NavBar(): JSX.Element {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showBurger, setShowBurger] = useState(false);

    return (
        <>
            <HeaderWrapper>
                <Header>
                    <SöshmeLogo />
                    <LinksContainer>
                        <NavButton href='/'>Home</NavButton>
                        <div
                            onMouseEnter={() => setShowDropdown(true)}
                            onMouseLeave={() => setShowDropdown(false)}
                        >
                            <NavButton>Products ▼</NavButton>
                            {showDropdown && (
                                <Dropdown>
                                    <DropdownItem href='/products/profile'>
                                        Profile Features
                                    </DropdownItem>
                                    <DropdownItem href='/products/wallet'>
                                        Wallet Features
                                    </DropdownItem>
                                </Dropdown>
                            )}
                        </div>
                        <NavButton href='/about-us'>About Us</NavButton>
                        {/* <NavButton
                            rel='noopener noreferrer'
                            target='_blank'
                            href='https://substack.com/profile/38565263-soshme?r=myl5b&utm_campaign=profile&utm_medium=web&utm_source=copy'
                        >
                            Blog
                        </NavButton>
                        <NavButton
                            rel='noopener noreferrer'
                            target='_blank'
                            href='https://climate.stripe.com/4ZBlzM'
                        >
                            Climate
                        </NavButton> */}
                        <NavButton href='/faq'>Help</NavButton>
                        <NavButton href='/contact-us'>Contact Us</NavButton>
                        <ContactUsButton href='/signup'>Get Söshed</ContactUsButton>
                    </LinksContainer>
                    <div onClick={() => setShowBurger(true)}>
                        <Hamburger src={burger} />
                    </div>
                </Header>
            </HeaderWrapper>
            <MobileNav onClose={() => setShowBurger(false)} visible={showBurger} />
            {/* <WarningWrapper>
                <AlertBanner theme='warning'>
                    {`We are currently encountering bad actors utilizing Söshme to sign up
                    and transact using stolen identities, including billing addresses,
                    social security numbers, & banking information. To protect the
                    affected individuals, we‘re temporarily pausing all payouts/withdrawals services
                    on our platform. The issue is being investigated with our partners and
                    will soon be resolved. We apologize for the inconvenience to our
                    users. `}
                    <a href='mailto:services@sosh.me'>
                        Please contact us via email if you believe you have been affected.
                        →
                    </a>
                </AlertBanner>
            </WarningWrapper> */}
        </>
    );
}
