import CryptoJS from 'crypto-js';

const ACCESS_TOKEN_SECRET = process.env.REACT_APP_ACCESS_TOKEN_SECRET!;

// AES Text Decryption
export const decryptText = async (
    data: string
): Promise<string | string[] | undefined> => {
    try {
        const bytes = CryptoJS.AES.decrypt(data, ACCESS_TOKEN_SECRET);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } catch (err) {
        console.log(` There was an decrypting text: ${err}`);
        return undefined;
    }
};

export const textToJSON = (text: any) => {
    return JSON.parse(text as string);
};

export const checkPassword = (str: string) => {
    const regex =
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
    return regex.test(str);
}