import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../utils/theme.util';

interface Props {
    color: string;
    title: string;
    costText: string;
    costSubText: string;
    description: React.ReactChild | string;
    children: React.ReactChild | null;
}

const Container = styled.div<{ color: string }>`
    border-radius: 8px;
    border-top: 3px solid ${({ color }) => color};
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 325px;
    overflow: hidden;
    padding: 30px 28px 42px;
    margin-right: 2rem;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 1px 1px 15px -10px #000000;
    margin-bottom: 2rem;

    @media ${device.tabletS} {
        margin-left: 2rem;
    }
`;

const TitleText = styled.p`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    margin-bottom: 2rem;
`;

const CostText = styled.span<{ color: string }>`
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    color: ${({ color }) => color};
    margin-bottom: -8px;
`;

const CostSubText = styled.p`
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #5a7184;
    margin-bottom: 2rem;
`;

const Description = styled.div`
    display: flex;
    flex: 1;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #5a7184;
`;

const ChildContainer = styled.div`
    @media ${device.tabletS} {
        margin-top: 2rem;
    }
`;

export function PricingCard({
    color,
    title,
    costText,
    costSubText,
    description,
    children,
}: Props) {
    return (
        <Container color={color}>
            <TitleText>{title}</TitleText>
            <CostText color={color}>{costText}</CostText>
            <CostSubText>{costSubText}</CostSubText>
            <Description>{description}</Description>
            <ChildContainer>{children}</ChildContainer>
        </Container>
    );
}
