import React from 'react';
import styled from 'styled-components';
import { device, fonts } from '../../../utils/theme.util';

import integrations from '../../../assets/images/integrations.png';
import checkmark from '../../../assets/images/checkmark.png';

const IntegrationImage = styled.img`
    width: 30%;
    z-index: 4;
    display: block;
    top: 10%;
    right: 10%;
    position: absolute;
    align-self: flex-start;

    @media ${device.laptopM} {
        width: 40%;
    }

    @media ${device.tabletS} {
        width: 80%;
        right: 0%;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 0%;
        z-index: 2;
        display: flex;
        align-self: center;
        position: relative;
        // background-color: red;
    }
`;

const WhyPaySplitContainer = styled.div`
    width: 100%;
    display: flex;
    align-self: center;
    height: 500px;

    margin-top: 5%;
    z-index: 1;

    position: relative;
    justify-content: space-between;

    // background-color: red;

    @media ${device.tabletS} {
        flex-direction: column;
    }
`;

const TextContainer = styled.div`
    flex-direction: column;
    diplay: flex;
    margin-left: 10%;
    margin-top: 0%;
    z-index: 3;
    width: 38%;
    align-self: center;

    // background-color: blue;

    @media ${device.tabletS} {
        margin-top: 30%;

        align-self: center;

        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
`;

const TopText = styled.h1`
    font-famiy: ${fonts.primary};
    color: #183b56;
    background-color: transparent;
    font-size: 36px;
    font-weight: 600;

    text-align: left;

    margin-top: 18px;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 100%;

    @media ${device.tabletS} {
        font-size: 30px;
        text-align: center;
    }
`;

const NormalText = styled.p`
    font-famiy: ${fonts.primary};
    color: #5a7184;
    background-color: transparent;
    font-size: 18px;
    font-weight: 200;
    flex: 1;
    text-align: left;
    align-self: center;

    &:focus {
        outline: none;
    }
    diplay: flex;

    width: 100%;

    margin-top: 20px;

    @media ${device.tabletS} {
        text-align: center;
        width: 80%
        margin-left: 10%;
        margin-right: 10%;
    }
`;

const CheckmarkColumn = styled.div`
    flex-direction: column;
    width: 100%;
    margin-top: 10%;
`;

const CheckmarkRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    align-self: center;

    justify-content: center;
`;

const CheckmarkImage = styled.img`
    height: 25px;
    width: 25px;
    margin-top: 22px;
`;

const OuterContainer = styled.div`
    width: 100%;
    display: wrap;
    position: relative;
    background-color: #fff;

    @media ${device.tabletS} {
        padding-bottom: 45%;
    }
`;

export function Integrations() {
    // eslint-disable-next-line global-require
    const Slide = require('react-reveal/Slide');

    return (
        <OuterContainer>
            <WhyPaySplitContainer>
                <IntegrationImage src={integrations} />
                <Slide left>
                    <TextContainer>
                        <TopText>Start your profile page within minutes</TopText>
                        <NormalText>
                            Customize your profile, add your latest content, and guide
                            your audiences to the places you’d like them to go.
                        </NormalText>
                        <NormalText>
                            Start accepting payments and donations within minutes. Get
                            started for free. All of the features you&apos;d expect — and
                            more — with no upfront cost.
                        </NormalText>

                        <CheckmarkColumn>
                            <CheckmarkRow>
                                <CheckmarkImage src={checkmark} />
                                <NormalText style={{ marginLeft: 10 }}>
                                    Creator Support
                                </NormalText>
                            </CheckmarkRow>

                            <CheckmarkRow>
                                <CheckmarkImage src={checkmark} />
                                <NormalText style={{ marginLeft: 10 }}>
                                    Unlimited links
                                </NormalText>
                            </CheckmarkRow>

                            <CheckmarkRow>
                                <CheckmarkImage src={checkmark} />
                                <NormalText style={{ marginLeft: 10 }}>
                                    Payment request
                                </NormalText>
                            </CheckmarkRow>
                        </CheckmarkColumn>
                    </TextContainer>
                </Slide>
            </WhyPaySplitContainer>
        </OuterContainer>
    );
}
