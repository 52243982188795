import React from 'react';
import styled from 'styled-components';
import { InfoRow } from '../../../common/InfoRow';
import img1 from '../../../../assets/images/products/wallet/Wallet-Small 1.png';
import img2 from '../../../../assets/images/products/wallet/Wallet-Small 2.png';
import img3 from '../../../../assets/images/products/wallet/Wallet-Small 3.png';
import img4 from '../../../../assets/images/products/wallet/Wallet-Small 4.png';
import { device } from '../../../../utils/theme.util';

const DATA = [
    {
        image: img1,
        title: 'Send and receive money with anyone',
        subtitle:
            'Receive collaboration payments, paychecks, and direct deposits with your Söshme Wallet.',
    },
    {
        image: img3,
        title: 'Creator Support',
        subtitle:
            'Let your fans support you. Accept monetary support from your followers right on your page.',
    },
    {
        image: img2,
        title: 'Your business at a glance',
        subtitle: 'Financial and audience data from each platform, aggregated.',
    },
    {
        image: img4,
        title: 'Manage Payments',
        subtitle:
            'Manage payments seamlessly between brands, agencies, and audience in one place.',
    },
];

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin 175px auto 0px;
    align-items: center;
    border-bottom: 1px solid #E5EAF4;
    padding-bottom: 8rem;
`;

const HeaderText = styled.p`
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #183b56;
    max-width: 50%;
    margin-bottom: 5rem;

    @media ${device.laptopS} {
        max-width: 100%;
        margin: 0 1rem 5rem;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;

    @media ${device.laptopS} {
        flex-direction: column;
    }
`;

const StyledInfoRow = styled(InfoRow)`
    margin-right: 2rem;
    max-width: 600px;
    width: 95%;
    min-width: 450px;

    @media ${device.laptopS} {
        margin-right: 0px;
        margin-bottom: 2rem;
        min-width: 0px;
    }
`;

export function Subscription() {
    return (
        <Container>
            <HeaderText>
                Söshme Wallet subscription plans include these features
            </HeaderText>
            <Row>
                <StyledInfoRow index={0} DATA={DATA} />
                <StyledInfoRow index={1} DATA={DATA} />
            </Row>
            <Row>
                <StyledInfoRow index={2} DATA={DATA} />
                <StyledInfoRow index={3} DATA={DATA} />
            </Row>
        </Container>
    );
}
