import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import integrations from '../../assets/images/integrations.png';
import { device, theme } from '../../utils/theme.util';
import { Stats } from './Stats';
import { NavBar } from '../nav/NavBar';

const Container = styled.div`
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
    margin: 0 2rem;
`;

const Image = styled.img`
    margin-top: 60px;
    @media ${device.tabletL} {
        margin-top: 40px;
    }
    max-width: 500px;
    width: 100%;
    min-width: 200px;
    margin-bottom: 3rem;
`;

const NeverHeardText = styled.span`
    font-size: 1.875rem;
    line-height: 2.5rem;
    font-weight: 800;
    color: #1a2633;
    margin-bottom: 1.5rem;
    text-align: center;
`;

const PinkText = styled.span`
    color: #e86e8a;
`;

const subtextStyles = css`
    color: ${theme.gray500};
    text-align: center;
    font-size: 1.2rem;
    line-height: 2.3rem;
`;

const SubText = styled.span`
    ${subtextStyles}
`;

const Link = styled.a`
    ${subtextStyles}
    margin-bottom: 1.5rem;
`;

const Button = styled.a`
    text-decoration: none;
    border-radius: 50px;
    background-color: #295e85;
    display: flex;
    max-width: 400px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;
    margin: 0 2rem;
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding: 14px 26px;
    &:hover {
        transform: translate(0%, -5%);
        transition: 0.3s ease-out;
    }
`;

export const SignUpSuccess = () => {
    const params = useLocation<any>();
    const searchParams = new URLSearchParams(params.search as any);
    const userName = searchParams.get('userName');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const profileLink = `www.sosh.me/${userName}`;

    return (
        <>
            <NavBar />
            <Container>
                <Image src={integrations} />
                <NeverHeardText>
                    Never heard of <PinkText>Social Banking?</PinkText>
                </NeverHeardText>
                <SubText>
                    It's because we made it up! Let's make it into a thing.{' '}
                    <Link target='blank' href={profileLink}>
                        View your profile
                    </Link>
                </SubText>

                <Button
                    target='blank'
                    href='https://apps.apple.com/us/app/paysplit/id1547157801'
                >
                    Download app
                </Button>
            </Container>
            <Stats />
        </>
    );
};
