import React from 'react';
import lock from '../../../../assets/images/products/profile/Lock.png';
import graph from '../../../../assets/images/products/profile/Graph.png';
import instant from '../../../../assets/images/products/profile/Instant.png';
import shield from '../../../../assets/images/products/profile/Shield.png';
import creator from '../../../../assets/images/products/profile/Tiiffany-Profile.png';
import { ProductFeatures } from '../../../common/ProductFeatures';

const DATA = [
    {
        image: lock,
        title: 'Link your contact information',
        subtitle:
            'You can choose to link your email, phone number, and have a newsletter signup.',
    },
    {
        image: graph,
        title: 'Unlimited free links',
        subtitle:
            'Make it easy for your fans to find you across all your favorite platforms. Embed your Youtube, Twitch, and Souncloud content.',
    },
    {
        image: instant,
        title: 'All your eWallets in one place',
        subtitle:
            'Quickly accept payments over Venmo, Cashapp, Apple pay, and many others through your Söshme profile. ',
    },
    {
        image: shield,
        title: 'Quickly transfer Crypto',
        subtitle:
            'You can enter your BTC and ETH wallet to make it easy for others to find you and send you money. ',
    },
];

export function Features() {
    return <ProductFeatures DATA={DATA} creatorImage={creator} />;
}
