export interface Theme {
    white: string;
    gray400: string;
    gray500: string;
    gray700: string;
    gray800: string;
    red: string;
    blue: string;
    lightBlue: string;
    black: string;
}

// Theme colors.
export const theme: Theme = {
    white: '#fff',
    gray400: '#bababa',
    gray500: '#8C8A9A',
    gray700: '#818181',
    gray800: '#414042',
    red: '#E14161',
    blue: '#0075FF',
    lightBlue: '#1BBFF1',
    black: '#000',
};

// Responsive screen sizes for devices.
export const size = {
    mobileXS: '320px',
    mobileS: '414px',
    mobileL: '590px',
    tabletS: '730px',
    tabletM: '786px',
    tabletL: '960px',
    laptopS: '1172px',
    laptopM: '1442px',
    monitorS: '1900px',
};

// Media queries.
export const device = {
    mobileXS: `(max-width: ${size.mobileXS})`,
    mobileS: `(max-width: ${size.mobileS})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tabletS: `(max-width: ${size.tabletS})`,
    tabletM: `(max-width: ${size.tabletM})`,
    tabletL: `(max-width: ${size.tabletL})`,
    laptopS: `(max-width: ${size.laptopS})`,
    laptopM: `(max-width: ${size.laptopM})`,
    monitorS: `(min-width: ${size.monitorS})`,
};

// Fonts used throughout the app.
export const fonts = {
    // Poppins weights: 500, 600, 700
    primary: 'Poppins',
};
